import { forgotPassword, resetPassword } from '@common/services';

export const handleForgotPassword = async (props: {
  appId: string;
  value: string;
}) => {
  return await forgotPassword(props.appId, {
    email: props.value,
  });
};

export const handleResetPassword = async (props: {
  appId: string;
  email: string;
  value: string;
}) => {
  return await resetPassword(props.appId, {
    email: props.email,
    new_password: props.value,
  });
};

export const convertMessage = (props: { message: string; locale: string }) => {
  const isJa = props.locale === 'ja';
  switch (props.message) {
    case 'Email is not allowed to be empty':
      return isJa ? 'メールアドレスが空欄です' : props.message;

    case 'Email must be a valid email':
      return isJa ? 'メールアドレスが有効ではありません' : props.message;

    case 'User does not exist.':
      return isJa ? 'メールアドレスが存在しません' : props.message;

    case 'Your password temporary has experied.':
      return isJa ? '仮パスワードは有効ではありません' : props.message;

    case 'new password is not allowed to be empty':
      return isJa ? 'パスワードが空欄です' : props.message;

    default:
      return props.message;
  }
};
