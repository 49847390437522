import CusLabel from '@common/components/Label';
import { setValueInput } from '@common/redux/slice/formInputs';
import BindingComponent from '@common/screens/RenderScreen/BindingComponent';
import { Field } from '@common/types';
import React, { FC, useEffect, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { useDispatch } from 'react-redux';

type IProps = {
  checkboxProps: any;
  answerProps: any;
  field: Field;
};

const Checkbox: FC<IProps> = ({ field, checkboxProps, answerProps }) => {
  const { id, isReset } = checkboxProps;
  const { items = [] } = field;
  const dispatch = useDispatch();

  const [state, setState] = useState<string[]>([]);

  const handlePress = (value: string) => {
    if (state.includes(value)) {
      setState((preState) => preState.filter((item) => item !== value));
    } else {
      setState((preState) => [...preState, value]);
    }
  };

  useEffect(() => {
    dispatch(setValueInput({ [id]: state.join(', ') }));
  }, [dispatch, id, state]);

  useEffect(() => {
    if (isReset) setState([]);
  }, [isReset]);

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {items?.map((item: string, index: number) => {
        return (
          <View
            style={{ display: 'flex', flexDirection: 'row', marginBottom: 5 }}
            key={index}
          >
            <TouchableOpacity
              onPress={() => {
                handlePress(item);
              }}
              activeOpacity={0.4}
              hitSlop={{ left: 10, right: 10, top: 10, bottom: 10 }}
              style={{ width: 24 }}
            >
              <Icon
                size={24}
                name={
                  state.includes(item) ? 'check-box' : 'check-box-outline-blank'
                }
                color={
                  state.includes(item)
                    ? checkboxProps.activeColor
                    : checkboxProps.inactiveColor
                }
              />
            </TouchableOpacity>
            {!!item && (
              <View
                style={{
                  marginLeft: 5,
                  flex: 1,
                  justifyContent: 'center',
                }}
              >
                <BindingComponent
                  ObjectClass={CusLabel as FC}
                  obj={{
                    ...answerProps,
                    text: item,
                    multiline: true,
                    width: '100%',
                  }}
                />
              </View>
            )}
          </View>
        );
      })}
    </View>
  );
};

export default Checkbox;
