import { defaultURL } from '@common/constants/shared';
import { IImage } from '@common/types/element';
import { getValueBindingComponent } from '@common/utils/component';
import {
  getActions,
  getItemListClick,
} from '@common/utils/handleActions/func/helps';
import { get, isEmpty, isUndefined, pick } from 'lodash';
import React, { FC, useMemo } from 'react';
import { Image as RNImage, Platform, TouchableOpacity } from 'react-native';
import createStyles from './style';

const CusImage: FC<IImage> = (attrs) => {
  const url = useMemo(() => {
    const { imageType } = attrs;

    switch (imageType) {
      case 'uploaded':
        return attrs?.filename1x;

      case 'url':
        // return  get(
        //   dataBinding,
        //   `${id}.imageURL`,
        //   flattedSourceString(imageURL)
        // );
        return getValueBindingComponent(attrs, 'imageURL');

      default:
        // const imageBinding =
        //   get(dataBinding, `${id}.imageUrl`) ||
        //   get(dataBinding, `imageUrl`) ||
        //   get(dataBinding, `${id}.imageUrl.binding`);
        // return imageBinding?.url;
        return getValueBindingComponent(attrs, 'url');
    }
  }, [attrs]);

  const hasPlaceholder = get(attrs, 'imageUrl.binding.options', {});
  const isUrl =
    !url && hasPlaceholder.placeholderImageEnabled
      ? hasPlaceholder?.placeholderImage
      : isEmpty(hasPlaceholder) && !url
      ? ''
      : url;

  const styles = createStyles(attrs, Platform, isEmpty(isUrl));

  const handlePress = (event: any) => {
    event.preventDefault();
    const arrayAction = getActions(attrs.actions);

    attrs.onPress &&
      attrs.onPress(arrayAction, {
        itemListClick: getItemListClick(attrs?.record),
      });
  };

  return (
    <TouchableOpacity
      disabled={isEmpty(attrs.actions)}
      activeOpacity={1}
      style={styles.container}
      {...(attrs.actions && {
        onPress: handlePress,
      })}
    >
      {isEmpty(isUrl) &&
      !hasPlaceholder?.placeholderImageEnabled ? null : Platform.OS !==
        'web' ? (
        <RNImage
          style={styles.img}
          resizeMode={attrs?.imageResize === 'contain' ? 'contain' : 'cover'}
          resizeMethod={'resize'}
          source={{ uri: isUndefined(isUrl) ? defaultURL : isUrl }}
          defaultSource={
            hasPlaceholder?.placeholderImageEnabled
              ? hasPlaceholder?.placeholderImage
                ? hasPlaceholder?.placeholderImage
                : defaultURL
              : ''
          }
        />
      ) : (
        <img
          src={isUndefined(isUrl) ? defaultURL : isUrl}
          style={{
            ...pick(attrs, ['borderRadius', 'opacity']),
            objectFit: attrs?.imageResize === 'contain' ? 'contain' : 'cover',
            width: '100%',
            height: '100%',
          }}
        ></img>
      )}
    </TouchableOpacity>
  );
};

export default React.memo(CusImage);
