export default {
  name: 'HorizontalCardList',
  displayName: {
    en: 'Horizontal List',
    ja: '水平リスト',
  },
  // icon: './icon.png',
  // defaultWidth: 155,
  // defaultHeight: 44,
  resizeX: true,
  props: [
    {
      name: 'items',
      displayName: {
        en: 'What is this a list of?',
        ja: 'データベースの選択',
      },
      type: 'list',
    },
    {
      name: 'sortList',
      displayName: {
        en: 'Sorting',
        ja: '並び替え',
      },
      type: 'sort',
    },
    {
      name: 'maximumItems',
      displayName: {
        en: 'Maximum number of items',
        ja: '上限',
      },
      type: 'maximumItems',
    },

    {
      name: 'onPress',
      displayName: {
        en: 'List',
        ja: 'リスト',
      },
      type: 'action',
      role: 'listItem',
      reference: 'items',
    },
  ],
  childComponents: [
    {
      displayName: {
        en: 'Image',
        ja: '画像',
      },
      name: 'cardCover',
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'image',
          displayName: {
            en: 'Image Source',
            ja: '画像ソース',
          },
          type: 'image',
        },
      ],
    },
    {
      displayName: {
        en: 'Avatar Image',
        ja: 'アバター画像',
      },
      name: 'avatarImage',
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'image',
          displayName: {
            en: 'Image Source',
            ja: '画像ソース',
          },
          type: 'image',
        },
        {
          name: 'enabled',
          type: 'boolean',
          default: 'true',
        },
      ],
    },
    {
      name: 'tag',
      displayName: {
        en: 'Tag',
        ja: 'タグ',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: 'true',
        },
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Button',
          styles: {
            fontFamily: '@body',
            fontWeight: '600',
          },
        },
        {
          name: 'icon',
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          type: 'icon',
          default: 'add',
        },
        // {
        //   name: 'tagLineNum',
        //   displayName: {
        //     en: 'Number of Lines',
        //     ja: '行数',
        //   },
        //   type: 'number',
        //   default: 1,
        //   control: {
        //     type: 'menu',
        //     options: [
        //       {
        //         label: 'canvas.menuRight.elementTab.list.singleLine',
        //         value: 1,
        //       },
        //       {
        //         label: 'canvas.menuRight.elementTab.list.doubleLine',
        //         value: 2,
        //       },
        //     ],
        //   },
        // },
      ],
    },
    {
      name: 'title',
      displayName: {
        en: 'Title',
        ja: 'タイトル',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Title',
          styles: {
            fontFamily: '@body',
            color: '@text',
            fontWeight: '500',
            fontSize: '20',
            textAlignment: 'left',
          },
        },
        {
          name: 'enabled',
          type: 'boolean',
          default: 'true',
        },
        {
          name: 'icon',
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          type: 'icon',
          default: '',
        },
        {
          name: 'titleLineNum',
          displayName: {
            en: 'Number of Lines',
            ja: '行数',
          },
          type: 'number',
          default: 1,
          control: {
            type: 'menu',
            options: [
              {
                label: 'canvas.menuRight.elementTab.list.singleLine',
                value: 1,
              },
              {
                label: 'canvas.menuRight.elementTab.list.doubleLine',
                value: 2,
              },
            ],
          },
        },
      ],
    },
    {
      name: 'subTitle',
      displayName: {
        en: 'Sub Title',
        ja: 'サブタイトル',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Sub Title',
          styles: {
            fontFamily: '@body',
            color: '@text',
            fontWeight: '400',
            fontSize: '14',
            textAlignment: 'left',
          },
        },
        {
          name: 'enabled',
          type: 'boolean',
          default: 'true',
        },
        {
          name: 'icon',
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          type: 'icon',
          default: '',
        },
        {
          name: 'subtitleLineNum',
          displayName: {
            en: 'Number of Lines',
            ja: '行数',
          },
          type: 'number',
          default: 1,
          control: {
            type: 'menu',
            options: [
              {
                label: 'canvas.menuRight.elementTab.list.singleLine',
                value: 1,
              },
              {
                label: 'canvas.menuRight.elementTab.list.doubleLine',
                value: 2,
              },
            ],
          },
        },
      ],
    },
    {
      name: 'subTitle2',
      displayName: {
        en: 'Sub Title 2',
        ja: 'サブタイトル 2',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: 'true',
        },
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Button',
          styles: {
            fontFamily: '@body',
            fontWeight: '600',
          },
        },
        {
          name: 'icon',
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          type: 'icon',
          default: 'add',
        },
        // {
        //   name: 'subtitleLineNum',
        //   displayName: {
        //     en: 'Number of Lines',
        //     ja: '行数',
        //   },
        //   type: 'number',
        //   default: 1,
        //   control: {
        //     type: 'menu',
        //     options: [
        //       {
        //         label: 'canvas.menuRight.elementTab.list.singleLine',
        //         value: 1,
        //       },
        //       {
        //         label: 'canvas.menuRight.elementTab.list.doubleLine',
        //         value: 2,
        //       },
        //     ],
        //   },
        // },
      ],
    },
    {
      name: 'footer',
      displayName: {
        en: 'Footer',
        ja: 'フッター',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: 'Title',
          styles: {
            fontFamily: '@body',
            color: '@text',
            fontWeight: '500',
            fontSize: '20',
            textAlignment: 'left',
          },
        },
        {
          name: 'enabled',
          type: 'boolean',
          default: 'true',
        },
        {
          name: 'icon',
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          type: 'icon',
          default: '',
        },
        {
          name: 'footerLineNum',
          displayName: {
            en: 'Number of Lines',
            ja: '行数',
          },
          type: 'number',
          default: 1,
          control: {
            type: 'menu',
            options: [
              {
                label: 'canvas.menuRight.elementTab.list.singleLine',
                value: 1,
              },
              {
                label: 'canvas.menuRight.elementTab.list.doubleLine',
                value: 2,
              },
            ],
          },
        },
      ],
    },
  ],
};
