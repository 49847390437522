import {
  bindingComponentType,
  isShowLoadingAction,
} from '@common/constants/shared';
import ActionWrapper from '@common/screens/LoadingAction';
import { IOnPressProps } from '@common/types/action';
import { ElementType } from '@common/types/index';
import { getSourceDataBinding } from '@common/utils/binding';
import {
  checkingTypeComponent,
  convertParamAction,
  getFlattedMetadata,
  memoriesConditional,
} from '@common/utils/component';
import { actionPromise } from '@common/utils/handleActions/excuteAction';
import { find, get } from 'lodash';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import ListObject from './Components/ListObject';
import SimpleObject from './Components/SimpleObject';
import DependenciesConnector from './DependenciesConnnector';

type Props = {
  ObjectClass: FC;
  obj: ElementType;
  dependencies: any;
};

const BindingComponent = React.memo(
  ({ ObjectClass, obj, dependencies }: Props) => {
    const { messages } = useIntl();
    const [loading, setLoading] = useState<boolean>(false);

    const onPress = useCallback(
      async (actionId: string | null, options: IOnPressProps) => {
        try {
          if (!actionId || typeof actionId !== 'string') return;
          const metadata = getFlattedMetadata(obj.screenUuid);
          const actionObject = find(metadata, (item) =>
            JSON.stringify(item).includes(actionId)
          );
          if (!actionObject) return;
          setLoading(true);
          const objectId = actionObject.id;
          const arrayAction = get(obj, `actions.${actionId}.actions`, []);

          return actionPromise(
            convertParamAction({
              obj,
              dependencies,
              options,
              objectId,
              actionId,
              messages,
              arrayAction,
            })
          )
            .then((data) => {
              setLoading(false);
              return data;
            })
            .catch(() => {});
        } catch (error) {
          console.log('error', error);
          setLoading(false);
        }
      },
      [dependencies, messages, obj]
    );

    const dataBinding = getSourceDataBinding(obj);

    const props = useMemo(
      () => ({ ObjectClass, obj, onPress, dependencies }),
      [ObjectClass, obj, onPress, dependencies]
    );

    const componentType =
      checkingTypeComponent(obj) || bindingComponentType.SIMPLE;

    const loadingAction =
      loading && isShowLoadingAction.includes(obj.componentName);

    if (loadingAction) {
      return <ActionWrapper obj={obj} />;
    }

    if (componentType === bindingComponentType.MULTIPLE) {
      return <ListObject {...props} dataBinding={dataBinding} />;
    }

    return <SimpleObject {...props} />;
  },
  memoriesConditional(['obj', 'dependencies', 'ObjectClass', 'screenUuid'])
);

export default DependenciesConnector(BindingComponent);
