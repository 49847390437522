import CusLabel from '@common/components/Label';
import { dataTypes } from '@common/constants';
import { ComponentType, Field, IQuestion } from '@common/types';
import { uuidv4 } from '@common/utils/record';
import React, { Fragment } from 'react';
import { Text, View } from 'react-native';
import { createFieldStyles } from '../style';
import { getStyles } from '../util';
import BindingField from './BindingField';
import Checkbox from './Checkbox';
import NumberLabel from './NumberLabel';
import Radio from './Radio';

type Props = {
  object: IQuestion;
  field: Field;
  active: boolean;
  hasError: any;
  index: number;
  isReset: boolean;
};

const FormField = (props: Props) => {
  const {
    field,
    object: { fieldStyles, answerQuestions, id: objectId },
    index,
    hasError,
    isReset,
  } = props;
  const styles = createFieldStyles();

  const fieldId = `${objectId}-${field?.fid || field.fieldId}`;

  const {
    answersStyle,
    inputsStyle,
    labelsStyle,
    numberLabelsStyle,
    answersBackgroundColor,
  } = getStyles(fieldStyles);

  const getLabelProps = () => {
    // supply the normalized body font family just incase the field styles
    // do not have a fontFamily property
    // fontFamily = fontFamily || normalizeFontFamily('@body', branding);
    const id = uuidv4();

    return {
      id,
      type: ComponentType.label,
      ...labelsStyle,
      text: field.label,
      multiline: true,
      width: '100%',
      // height: 27,
      // lineHeight: 27,
    };
  };

  const getAnswerProps = () => {
    // supply the normalized body font family just incase the field styles
    // do not have a fontFamily property
    // fontFamily = fontFamily || normalizeFontFamily('@body', branding);
    const id = uuidv4();

    return {
      id,
      type: ComponentType.label,
      ...answersStyle,
      // height: 27,
      // lineHeight: 27,
    };
  };

  const renderBindingField = () => {
    const attributes = { id: fieldId, ...inputsStyle };

    switch (field.type) {
      case dataTypes.IMAGE_UPLOAD:
        attributes.type = ComponentType.imageUpload;
        attributes.color = inputsStyle.accentColor;
        attributes.imageUploadType = 'fullWidth';
        attributes.height = 150;
        break;

      case dataTypes.SELECT:
        attributes.type = ComponentType.select;
        attributes.seperate = {
          enabled: true,
          items: field?.items?.join('\n'),
        };
        attributes.height =
          inputsStyle.fontSize * 1.3 + 2 * inputsStyle.padding;
        attributes.placeholder = field.placeholder;
        break;

      default:
        attributes.defaultValue = field?.defaultValue;
        attributes.type = ComponentType.input;
        attributes.multiline = !field.numberOfLines;
        attributes.inputType = 'default';
        attributes.height =
          inputsStyle.fontSize * 1.3 + 2 * inputsStyle.padding;
        attributes.placeholder = field.placeholder;
        attributes.color = '#092636';
        break;
    }

    const inputProps = {
      ...attributes,
      field,

      borderColor: hasError
        ? inputsStyle.errorColor || 'red'
        : inputsStyle.borderColor || '#ddd',
      maxHeight: attributes.height,
    };

    return <BindingField inputProps={inputProps} />;
  };

  const renderRadio = () => {
    const radioProps = {
      id: fieldId,
      isReset,
      type: ComponentType.checkbox,
      activeColor: answersBackgroundColor,
      inactiveColor: '#ccc',
    };

    return (
      <Radio
        radioProps={radioProps}
        answerProps={getAnswerProps()}
        field={field}
      />
    );
  };

  const renderCheckbox = () => {
    const checkboxProps = {
      id: fieldId,
      isReset,
      type: ComponentType.checkbox,
      activeColor: answersBackgroundColor,
      inactiveColor: '#ccc',
    };

    return (
      <Checkbox
        checkboxProps={checkboxProps}
        answerProps={getAnswerProps()}
        field={field}
      />
    );
  };

  const renderErrorMessage = () => {
    if (!hasError) return <></>;
    const { message } = hasError;

    if (!message) return <></>;

    const errorStyles = {
      color: inputsStyle.errorColor,
    };

    return <Text style={[styles.errorMessage, errorStyles]}>{message}</Text>;
  };

  const renderInput = () => {
    switch (field.type) {
      case dataTypes.RADIO:
        return renderRadio();
      case dataTypes.TOGGLE:
        return renderCheckbox();

      default:
        return renderBindingField();
    }
  };

  const labelProps = getLabelProps();

  const renderField = () => {
    try {
      return (
        <View style={[styles.formRow]}>
          <View style={styles.fieldLabel}>
            <NumberLabel
              indexQuestion={index + 1}
              textQuestion={answerQuestions.text}
              numberLabelsStyle={numberLabelsStyle}
            />
            <View style={{ flex: 1 }}>
              <CusLabel {...labelProps}>
                {field?.required && (
                  <View>
                    <Text style={styles.startRequired}>*</Text>
                  </View>
                )}
              </CusLabel>
            </View>
          </View>
          {renderInput()}
          {hasError ? renderErrorMessage() : null}
        </View>
      );
    } catch (err) {
      console.log('err', err);
    }
  };

  return <Fragment>{renderField()}</Fragment>;
};

export default FormField;
