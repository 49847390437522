import { setValueInput } from '@common/redux/slice/formInputs';
import { ImagePicker } from '@common/utils/upload';
import React, { FC, useCallback, useState } from 'react';
import {
  ActivityIndicator,
  Image,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useDispatch } from 'react-redux';
import createStyles from './style';

export type imageObj = {
  url: string;
  filename?: string;
  size?: number;
  id?: string;
} | null;

type Props = {
  attributes: any;
  index: number;
  defaultImage: string | undefined;
  valueInputs: imageObj[];
};

const ImageUploadWeb: FC<Props> = ({
  attributes,
  index,
  defaultImage,
  valueInputs,
}) => {
  const dispatch = useDispatch();

  const changeInput = useCallback(
    (value: imageObj[]) => {
      dispatch(setValueInput({ [attributes.id]: value }));
    },
    [attributes.id, dispatch]
  );

  const uploadBg = require('./image/image-upload.png').default;

  const [error, setError] = useState('');

  const styles = createStyles(attributes);

  const [pending, setPending] = useState(false);

  const handlePress = () => {
    if (Platform.OS !== 'web') return;
    setPending(true);
    error && setError('');

    ImagePicker.showImagePicker(
      { quality: 0.7, setPending, imageUpload: true, setError, attributes },
      (response: any) => {
        if (response) {
          const valueInputsClone: imageObj[] = [...valueInputs];
          valueInputsClone[index] = response;
          changeInput(valueInputsClone);
          setPending(false);
        }
      }
    );
  };

  const Loading = () => {
    if (!pending) return null;
    return (
      <View>
        <ActivityIndicator />
        <Text style={{ color: 'white' }}>Uploading...</Text>
      </View>
    );
  };

  if (valueInputs?.[index]) {
    return (
      <React.Fragment>
        <View style={styles.innerWrapper}>
          <TouchableOpacity onPress={handlePress} style={{ flex: 1 }}>
            <View style={styles.preview}>
              <Image
                style={[styles.previewImage, pending ? styles.faded : {}]}
                source={{ uri: valueInputs[index]?.url }}
                resizeMode="contain"
              />
              <Loading />
            </View>
          </TouchableOpacity>
        </View>
        {valueInputs[index] ? null : (
          <TouchableOpacity onPress={handlePress} style={styles.selectAnother}>
            <Text style={[styles.selectImage]}>Change Photo</Text>
          </TouchableOpacity>
        )}
      </React.Fragment>
    );
  }

  return (
    <View style={[styles.innerWrapper]}>
      <TouchableOpacity style={styles.emptyWrapper} onPress={handlePress}>
        <View style={[styles.indexNumber]}>
          <Text style={{ fontSize: 10, color: 'black', fontWeight: 'bold' }}>
            {index + 1}
          </Text>
        </View>
        <Loading />
        {!pending && (
          <Image
            style={[styles.previewImage]}
            source={{ uri: defaultImage || uploadBg }}
            resizeMode="contain"
          />
        )}
      </TouchableOpacity>
    </View>
  );
};

export default ImageUploadWeb;
