import { APP_API_TOKEN, TOKEN_KEY } from '@common/constants/shared';
import {
  LoginSuccessParams,
  SignupSuccessParams,
  LoginSuccessWithLineParams,
} from '@common/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type StateAuth = {
  token: any;
  profile: any;
  isAuth: boolean;
  isOAuth: boolean;
  loading: boolean;
  screenId: any;
  friendship?: boolean;
  checkAuth: boolean;
};

const initialState: StateAuth = {
  token: null,
  profile: null,
  isAuth: false,
  isOAuth: false,
  loading: false,
  screenId: null,
  friendship: undefined,
  checkAuth: true,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    checkAuthSuccess: (state: StateAuth, action: PayloadAction<any>) => {
      const user = action.payload;
      if (user) {
        state.profile = user;
        state.isAuth = true;
      } else {
        state.isAuth = false;
        state.profile = null;
      }
      state.checkAuth = false;
    },
    checkAuthError: (state: StateAuth) => {
      state.profile = null;
      state.isAuth = false;
      state.checkAuth = false;
    },
    loginSuccess: (
      state: StateAuth,
      action: PayloadAction<LoginSuccessParams>
    ) => {
      const { user } = action.payload;
      state.profile = user;
      state.isAuth = true;
    },
    signupUserSuccess: (
      state: StateAuth,
      action: PayloadAction<SignupSuccessParams>
    ) => {
      const { user } = action.payload;
      state.profile = user;
      state.isAuth = true;
    },
    updateLoggedUserSuccess: (
      state: StateAuth,
      action: PayloadAction<LoginSuccessParams>
    ) => {
      const { user } = action.payload;
      state.profile = {
        ...state.profile,
        ...user,
      };
      state.isAuth = true;
    },
    signOut: (state: StateAuth, action: PayloadAction<any>) => {
      state.isAuth = false;
      state.profile = null;
      localStorage.setItem(TOKEN_KEY, APP_API_TOKEN);
    },
    loginSuccessWithLine: (
      state: StateAuth,
      action: PayloadAction<LoginSuccessWithLineParams>
    ) => {
      const { user, token, friendship } = action.payload;
      state.profile = user;
      state.isAuth = true;
      state.token = token;
      state.friendship = friendship;
    },
    loginSuccessWithOAuth: (
      state: StateAuth,
      action: PayloadAction<LoginSuccessWithLineParams>
    ) => {
      const { user, token } = action.payload;
      state.profile = user;
      state.isAuth = true;
      state.isOAuth = true;
      state.token = token;
    },
    loginSuccessWithApple: (state: StateAuth, action: PayloadAction<any>) => {
      const { user, token } = action.payload;
      state.profile = user;
      state.isAuth = true;
      state.isOAuth = true;
      state.token = token;
    },
  },
});

export const {
  signOut,
  loginSuccess,
  checkAuthError,
  signupUserSuccess,
  updateLoggedUserSuccess,
  loginSuccessWithLine,
  loginSuccessWithOAuth,
  loginSuccessWithApple,
  checkAuthSuccess,
} = authSlice.actions;
export default authSlice.reducer;
