import { RootState } from '../store';

export const currentRecordSelector = (state: RootState) =>
  state.database.currentRecord;

export const web3ConfigSelector = (state: RootState) =>
  state.database.web3Config;

export const realtimeDatabaseSelector = (state: RootState) =>
  state.database.databaseRefresh;
