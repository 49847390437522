import { IChipList } from '@nocode/types';
import { get, isEmpty } from 'lodash';
import queryString from 'query-string';
import React, { useMemo, useRef } from 'react';
import { ActivityIndicator, FlatList, Platform, View } from 'react-native';
import Icon from '../AppBar/Icon';
import EmptyList from '../EmptyList';
import Loading from '../Loading';
import ChipItem from './Chip';
import { getItemChipList } from './func';
import createStyles from './style';

const ChipList = (attrs: IChipList) => {
  const { page = 1, totalPage, onLoadMore } = attrs;

  const { initializeList } = attrs;
  const flatListRef: any = useRef();

  const data = useMemo(() => {
    return getItemChipList(attrs);
  }, [attrs]);

  const styles = createStyles(attrs);
  const tableId = get(attrs, 'databaseOptions.tableId');

  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};

  const target = search?.target;
  const isCanvas = Platform.OS === 'web' && !target;

  if (target && !tableId && !attrs?.loading)
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          zIndex: attrs.zIndex,
        }}
      >
        <EmptyList attributes={attrs} />
      </View>
    );

  const onEndReached = () => {
    if (attrs?.isLoadMore || page >= totalPage) return;
    onLoadMore && onLoadMore();
  };

  return initializeList ? (
    <Loading />
  ) : (
    <View style={styles.wapperList}>
      <FlatList
        data={data}
        horizontal
        // pagingEnabled={Platform.OS === 'web'}
        scrollEnabled
        snapToAlignment="start"
        scrollEventThrottle={16}
        decelerationRate={'normal'}
        bounces={false}
        showsHorizontalScrollIndicator={false}
        ref={flatListRef}
        onEndReachedThreshold={0.1}
        {...(attrs?.isLoadMore && {
          ListFooterComponent: (
            <View
              style={{
                display: 'flex',
                height: attrs.height,
                justifyContent: 'center',
              }}
            >
              <ActivityIndicator size="small" color="#1976d2" />
            </View>
          ),
        })}
        onEndReached={onEndReached}
        renderItem={({ item, index }) => (
          <ChipItem
            item={item}
            key={index}
            index={index}
            attrs={attrs}
            onPress={attrs.onPress}
          />
        )}
        keyExtractor={(item, index) => `${(item?._id, index)}`}
      />

      {isCanvas && <Icon name="ellipsis-horizontal" />}
    </View>
  );
};

export default ChipList;
