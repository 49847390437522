import { simpleBindingSelector } from '@common/redux/selectors/page';
import { fetchComponentBinding } from '@common/services';
import { IRecord } from '@common/types/database';
import { ITable } from '@common/types/element';
import { GetComponentBindingResult } from 'click-types';
import { get, isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

const useTable = ({ obj, filterText }: { obj: ITable; filterText: string }) => {
  const [page, setPage] = useState(1);
  const [initializeList, setInitializeList] = useState(true);
  const [isVisible, setVisible] = useState(true);

  const dataFormPage = useSelector(simpleBindingSelector(obj.id));

  const [data, setData] = useState({
    total: 1,
    dataBinding: [] as any[],
  });

  const handleChangePage = (page: number) => setPage(page);

  const visibility = useMemo(
    () => get(dataFormPage, 'visibility', isVisible),
    [dataFormPage, isVisible]
  );

  const fetchData = useCallback(
    async ({ controller }: { controller: any }) => {
      try {
        const { pagination, dependencies, screenUuid, dataBinding } = obj;
        const tableId = get(dataBinding, 'source.tableId');

        //table is not selected
        if (!tableId) return;

        const { appInfor } = dependencies;
        const { limit = null } = dataBinding?.source?.options || {};
        const { pageSize } = pagination;

        const componentBindingResult: GetComponentBindingResult =
          await fetchComponentBinding({
            appId: appInfor.id,
            dependencies,
            screenUuid: screenUuid,
            controller,
            idComponent: obj.id,
            page,
            limit: pageSize,
            filterText,
          });

        if (
          !isEmpty(componentBindingResult?.data) &&
          Array.isArray(componentBindingResult?.data)
        ) {
          const dataTotal = componentBindingResult.total;
          const total =
            !limit || (limit && limit > dataTotal) ? dataTotal : limit;
          const formatRecord =
            componentBindingResult?.data.length &&
            Array.isArray(componentBindingResult?.data)
              ? componentBindingResult.data.map((record: IRecord) => ({
                  ...record,
                  _meta: {
                    tableId: tableId,
                    datasourceId: obj.id,
                    databindingId: dataBinding?.id,
                  },
                }))
              : [];

          setData({
            total: total,
            dataBinding: formatRecord,
          });
        }

        // check visibility in list, dropdown on api list
        setVisible(get(data, 'data.visibility', true));
      } catch (error) {
      } finally {
        setInitializeList(false);
      }
    },
    [page, obj, filterText]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchData({ controller });

    return () => {
      controller.abort();
    };
  }, [fetchData]);

  return { handleChangePage, initializeList, ...data, visibility };
};

export default useTable;
