import useShareSNS from '@common/hooks/shareSNS';
import React, { useCallback, useEffect, useState } from 'react';
import { NativeModules, Platform, Text, View } from 'react-native';
import { useParams } from '@common/routes/hooks';

function ShareLink() {
  const { getShareLink } = useShareSNS();
  const params = useParams();

  const [longUrl, setLongUrl] = useState<any>('');
  const [isOpenUrl, setOpenUrl] = useState<any>(false);

  useEffect(() => {
    getShareLink(params?.shortUrl).then(
      (data) => data?.longUrl && setLongUrl(data.longUrl)
    );
  }, [getShareLink, params?.shortUrl]);

  const urlSearchParams = new URLSearchParams(`${longUrl}`);
  const deepLink = urlSearchParams.get('deepLink');
  const app = urlSearchParams.get('app');
  const iosAppId = urlSearchParams.get('iosAppId');
  const iosAppName = urlSearchParams.get('iosAppName');

  const renderItem = useCallback(() => {
    if (Platform.OS == 'web') {
      if (longUrl && !isOpenUrl) {
        setOpenUrl(true);

        const locale =
          NativeModules?.SettingsManager?.settings?.AppleLocale ||
          NativeModules?.SettingsManager?.settings?.AppleLanguages?.[0];

        const localeArr = typeof locale === 'string' ? locale.split('_') : [];

        const country = localeArr.length
          ? localeArr[localeArr.length - 1].toLowerCase()
          : null;

        if (deepLink || app) {
          const tryClose = function () {
            location.replace('about:blank');
          };
          const start = Date.now();
          const fallbackToStore = function () {
            const now = Date.now();
            if (now - start > 600) {
              tryClose();
            } else {
              if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
                window.location.replace(`market://details?id=${app}`);
              }
              if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
                if (iosAppId && iosAppName) {
                  const urlAppStore = `https://apps.apple.com/${
                    country ? country : `jp`
                  }/app/${iosAppName}/${iosAppId}`;
                  window.location.replace(urlAppStore);
                }
              }

              setTimeout(tryClose, 500);
            }
          };

          const triggerAppOpen = function () {
            window.location.replace(`${deepLink}://?${longUrl}`);
          };

          triggerAppOpen();
          setTimeout(fallbackToStore, 500);
        }
      }
      return (
        <View
          style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
        >
          <Text>Open App...</Text>
        </View>
      );
    } else {
      return (
        <View>
          <Text>Cannot support for OS version</Text>
        </View>
      );
    }
  }, [longUrl, isOpenUrl, deepLink, app, iosAppId, iosAppName]);

  if (!params?.shortUrl) {
    return (
      <View>
        <Text>Cannot found page</Text>
      </View>
    );
  }

  return <>{renderItem()}</>;
}

export default ShareLink;
