import { useCustomProp } from '@common/components/LibraryComponent/customProp';
import Loading from '@common/components/Loading';
import {
  setDefaultValueInput,
  setValueInput,
} from '@common/redux/slice/formInputs';
import { ILibraryComponent } from '@common/types/element';
import { get } from 'lodash';
import React, { FC, useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { useDispatch } from 'react-redux';
const libraries = require('@nocode/components');

import createStyles from './style';

declare global {
  interface Window {
    [key: string]: any;
  }
}

const CusLibraryComponent: FC<ILibraryComponent> = (attributes) => {
  const Component = libraries[attributes.componentName];

  const styles = createStyles(attributes);
  const { getCustomProp, loading } = useCustomProp(attributes);
  const dispatch = useDispatch();

  const valueInputs = useMemo(
    () => get(attributes, `dependencies.valueInputs.${attributes.id}`, false),
    [attributes]
  );

  const changeInput = useCallback(
    (value: any) => {
      dispatch(
        setValueInput({
          [attributes.id]: value,
        })
      );
    },
    [attributes.id, dispatch]
  );

  const setDefaultValue = useCallback(
    (value: any) => {
      dispatch(
        setDefaultValueInput({
          [attributes.id]: value,
        })
      );
    },
    [attributes.id, dispatch]
  );

  if (loading) {
    return <Loading />;
  }

  if (!Component) return <></>;

  return (
    <View style={styles.container}>
      <Component
        {...attributes}
        {...getCustomProp()}
        valueInputs={valueInputs}
        changeInput={changeInput}
        setDefaultValue={setDefaultValue}
      >
        {attributes?.children}
      </Component>
    </View>
  );
};

export default CusLibraryComponent;
