import { CardElement, Elements } from '@stripe/react-stripe-js';
import {
  loadStripe,
  Stripe,
  StripeCardElement,
  StripeElementLocale,
  StripeElements,
} from '@stripe/stripe-js';

import React, { useCallback } from 'react';
import { Text, View } from 'react-native';
import DefaultCard from './DefaultCard';
import Form from './Form';
import createStyles, { IStripePaymentSecurity } from './style';
import SubmitButton from './SubmitButton';
import { useStripeSecurity } from './useStripeSecurity';
import { get } from 'lodash';
import { getValueBinding } from '../shared';

const StripeWeb: React.FC<IStripePaymentSecurity> = (props) => {
  const {
    attributes,
    width,
    height,
    opacity,
    publicKey: _publicKey,
    accountConnectId,
    locale,
    data,
  } = props;

  const bindingOptions = getValueBinding('', data, props);

  const { title, rememberCheckbox } = attributes;

  const {
    publicKey,
    loading,
    rememberCard,
    cardDefaultInfo,
    onClickCheckBox,
    handlePayment,
    message,
    setMessage,
    isSaveCardAction,
  } = useStripeSecurity(props);

  const styles = createStyles({
    attrs: attributes,
    width,
  });

  const { enabled: titleEnabled = true } = title || {};

  const onChangeCard = useCallback((e) => {
    setMessage({
      error: e?.error?.message,
    });
  }, []);

  const handleSubmit = async ({
    stripe,
    elements,
  }: {
    stripe: Stripe | null;
    elements: StripeElements | null;
  }) => {
    if (loading) {
      return;
    }
    setMessage({});
    const handleCreatePaymentMethod = async () => {
      const stripeCardElement: StripeCardElement =
        elements?.getElement(CardElement)!;
      const paymentMethod = await stripe?.createPaymentMethod({
        type: 'card',
        card: stripeCardElement,
        billing_details: {
          email: get(bindingOptions, 'email.emailBuyer'),
        },
      });
      return paymentMethod;
    };
    handlePayment(handleCreatePaymentMethod);
  };

  const renderSubmitButton = useCallback(
    ({ stripe, elements }) => {
      return (
        <SubmitButton
          {...{
            loading,
            handleSubmit: () => handleSubmit({ stripe, elements }),
            isSaveCardAction,
            rememberCard,
            styles,
            text: get(bindingOptions, 'submitButton.text'),
          }}
        />
      );
    },
    [
      loading,
      handleSubmit,
      attributes?.submitButton?.text,
      rememberCard,
      isSaveCardAction,
    ]
  );

  if (cardDefaultInfo?.brand) {
    return (
      <View
        style={{
          width,
          height,
          opacity,
        }}
      >
        <DefaultCard
          {...{
            cardDefaultInfo,
            message,
            rememberCheckbox,
            titleEnabled,
            titleText: get(bindingOptions, 'title.text'),
          }}
        />
        {renderSubmitButton({})}
      </View>
    );
  }

  return (
    <View
      style={{
        width,
        height,
        opacity,
        justifyContent: 'space-around',
        alignContent: 'center',
      }}
    >
      {titleEnabled && <Text>{get(bindingOptions, 'title.text')}</Text>}
      <Elements
        stripe={loadStripe(publicKey, {
          stripeAccount: accountConnectId || '',
        })}
        options={{ locale: locale as StripeElementLocale }}
      >
        <Form
          {...{
            onChangeCard,
            message,
            styles,
            renderSubmitButton,
            rememberCard,
            attributes,
            onClickCheckBox,
          }}
        />
      </Elements>
    </View>
  );
};
export default StripeWeb;
