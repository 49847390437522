import { useState, useEffect, useRef, useMemo } from 'react';
import { Platform } from 'react-native';
import { get } from 'lodash';
import cardValidator from 'card-validator';
import {
  getTextErrors,
  cardNumberFormatter,
  cardExpirationDateFormatter,
  amountFormatter,
  toNonAccentVietnamese,
  CARD,
  STATUS_UNIVAPAY_SUBSCRIPTION,
} from './constant';
import { getMessage } from '../../utils/locale';
import {
  URI_IMAGE_CARD,
  checkTypeCardPayment,
} from './../../utils/cardPayment';
import { getValueBinding } from '../shared';

export type ReceivedProps = {
  actions: any;
  attributes: Record<string, any>;
  isConnected: boolean;
  univaPayPayment: (data: any) => Promise<any>;
  onPress: (id: string | undefined, options: any) => void;
  locale: string;
  data: Record<string, any>;
  id: string;
  paramsPayload: { appId: string };
  dependencies: any;
  screenUuid: string;
  onPressAction: (actions: any[]) => any;
};

const initialState = {
  cardName: '',
  cardNumber: '',
  cardExpiration: '',
  cardCvc: '',
  text: { status: null, message: null },
};

type CHECK_CARD = {
  niceType: string;
  type: URI_IMAGE_CARD;
  patterns: number[] | null;
  gaps: number[] | null;
  lengths: number[];
  code: {
    name: string;
    size: number;
  };
  matchStrength?: number | null;
};

const useUnivaPaySubscription = (props: ReceivedProps) => {
  const {
    actions,
    attributes,
    univaPayPayment,
    onPress,
    locale,
    id,
    data,
    isConnected,
    paramsPayload,
    dependencies,
    screenUuid,
    onPressAction,
  } = props;

  const bindingValue = useMemo(() => getValueBinding(id, data, props), [props]);

  const emailBuyer = get(bindingValue, 'email.emailBuyer');
  const paymentAmount = get(bindingValue, 'paymentOptions.paymentAmount');
  const typeCurrency = get(bindingValue, 'paymentOptions.typeCurrency');
  const period = get(bindingValue, 'paymentOptions.period');
  const submitButtonText = get(bindingValue, 'submitButton.text') || 'Pay Now';

  const textErrors = getTextErrors();

  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState<{
    cardName: string;
    cardNumber: string;
    cardExpiration: string;
    cardCvc: string;
    text: {
      status: 'completed' | 'failed' | 'notValidOrRequired' | null;
      message: string | null;
    };
  }>(initialState);

  const cardNameRef = useRef<any>(null);
  const cardNumberRef = useRef<any>(null);
  const [cursor, setCursor] = useState<Record<string, any>>({
    cursorCardName: null,
  });

  const { cardName, cardNumber, cardExpiration, cardCvc, text } = card;

  const checkTypeCard: CHECK_CARD = checkTypeCardPayment(cardNumber);

  const uriImage = !cardNumber
    ? URI_IMAGE_CARD['default']
    : cardNumber
    ? URI_IMAGE_CARD[checkTypeCard.type]
    : URI_IMAGE_CARD['invalid'];

  const isPassCardName =
    cardName && new RegExp(/^[A-Za-z].*.*[A-Za-z]$/g).test(cardName);

  const isPassCardNumber = checkTypeCard.lengths.includes(
    cardNumber.replace(/\s/g, '').length
  );

  const isPassCardExpiration =
    cardExpiration && cardValidator.expirationDate(cardExpiration).isValid;

  const isPassCardCvc = cardCvc && cardCvc.length === checkTypeCard.code.size;

  const isPassEmail =
    emailBuyer &&
    emailBuyer
      .toString()
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  const isCanvas =
    window?.location?.href &&
    window.location.href.split('/').includes('canvas');

  const isDisableButton =
    loading ||
    (text.message && text.status === 'notValidOrRequired') ||
    !isPassCardName ||
    checkTypeCard.type === 'invalid' ||
    !isPassCardNumber ||
    !isPassCardExpiration ||
    !isPassCardCvc ||
    !isPassEmail;

  const amountPayload = amountFormatter(paymentAmount, typeCurrency);

  useEffect(() => {
    setCard(initialState);
  }, [id]);

  useEffect(() => {
    if (!isCanvas) {
      const checkStatusCard =
        !isConnected ||
        !isPassEmail ||
        amountPayload === 0 ||
        !isPassCardName ||
        !isPassCardNumber ||
        !isPassCardExpiration ||
        !isPassCardCvc
          ? 'notValidOrRequired'
          : null;
      const checkMessageCard = !isConnected
        ? textErrors.connectionRequired
        : !isPassEmail
        ? textErrors.emailInvalid
        : amountPayload === 0
        ? getMessage({ id: 'INVALID_AMOUNT', locale })
        : cardName && !isPassCardName
        ? textErrors.cardNameInvalid
        : cardNumber && !isPassCardNumber
        ? textErrors.cardNumberInvalid
        : cardExpiration && !isPassCardExpiration
        ? textErrors.cardExpirationInvalid
        : cardCvc && !isPassCardCvc
        ? textErrors.cardCvcInvalid
        : null;

      setCard((prev) => ({
        ...prev,
        text: {
          status: checkStatusCard,
          message: checkMessageCard,
        },
      }));
    }
  }, [
    isConnected,
    emailBuyer,
    amountPayload,
    cardName,
    cardNumber,
    cardExpiration,
    cardCvc,
  ]);

  const handleCheckRequired = () => {
    if (!text.status) {
      const keys: Record<string, string> = {
        cardName: cardName,
        cardNumber: cardNumber,
        cardExpiration: cardExpiration,
        cardCvc: cardCvc,
        emailBuyer: emailBuyer,
      };

      const cardRequired = Object.keys(keys).find((key) => !keys[key]);
      if (!cardRequired) {
        setCard((prev) => ({ ...prev, text: { status: null, message: null } }));
        return;
      }

      const checkStatusCardRequired = [
        CARD.CARD_NAME,
        CARD.CARD_NUMBER,
        CARD.CARD_EXPIRATION,
        CARD.CARD_CVC,
      ].includes(cardRequired)
        ? 'notValidOrRequired'
        : null;
      const checkMessageCardRequired =
        cardRequired === CARD.CARD_NAME
          ? textErrors.cardNameRequired
          : cardRequired === CARD.CARD_NUMBER
          ? textErrors.cardNumberRequired
          : cardRequired === CARD.CARD_EXPIRATION
          ? textErrors.cardExpirationRequired
          : cardRequired === CARD.CARD_CVC
          ? textErrors.cardCvcRequired
          : cardRequired === 'emailBuyer'
          ? textErrors.emailRequired
          : null;

      setCard((prev) => ({
        ...prev,
        text: {
          status: checkStatusCardRequired,
          message: checkMessageCardRequired,
        },
      }));
    } else {
      setCard((prev) => ({ ...prev, text: { status: null, message: null } }));
    }
  };

  const handleChange = (value: string, name: string) => {
    setCard((prev) => {
      switch (name) {
        case CARD.CARD_NAME:
          return {
            ...prev,
            [name]: toNonAccentVietnamese(value.toUpperCase()),
          };
        case CARD.CARD_NUMBER:
          return {
            ...prev,
            [name]: cardNumberFormatter(prev.cardNumber, value),
          };
        case CARD.CARD_EXPIRATION:
          return {
            ...prev,
            [name]: cardExpirationDateFormatter(prev.cardExpiration, value),
          };
        default:
          return { ...prev, [name]: value };
      }
    });
  };

  const handleSubmit = async () => {
    handleCheckRequired();
    if (text.status && text.status !== 'completed') {
      return;
    }
    try {
      setLoading(true);

      const { appId } = paramsPayload || {};

      const payload = {
        paymentType: 'card',
        type: 'subscription',
        period: period,
        dataCard: {
          cardholder: cardName,
          card_number: cardNumber.replace(/\s/g, ''),
          exp_month: cardExpiration.split('/')[0],
          exp_year: cardExpiration.split('/')[1],
          cvv: cardCvc,
          // cvv_authorize: {
          //   enabled: true,
          // },
        },
        saveCardOptions: { saveCard: false, onlySaveCard: false },
        objectId: id,
        appId: appId,
        screenId: screenUuid,
        dependencies,
      };

      const response = await univaPayPayment(payload);

      if (
        response.status === STATUS_UNIVAPAY_SUBSCRIPTION.COMPLETED ||
        response.status === STATUS_UNIVAPAY_SUBSCRIPTION.CURRENT
      ) {
        setCard((prev) => ({
          ...prev,
          text: { status: 'completed', message: 'Payment successful' },
        }));
        handleAction(response.actions);
      } else {
        const getError = get(response, 'error.message');
        setCard((prev) => ({
          ...prev,
          text: {
            status: 'failed',
            message: getMessage({ id: getError, locale }),
          },
        }));
        handleAction(response?.actions);
      }
    } catch (error: any) {
      let getError: any = null;
      getError = get(error?.response, 'data.data.code');
      if (['VALIDATION_ERROR'].includes(getError)) {
        getError = get(error?.response, 'data.errors[0].reason') || 'Error';
      }

      setCard((prev) => ({
        ...prev,
        text: {
          status: 'failed',
          message: getMessage({ id: getError, locale }),
        },
      }));

      handleAction(error?.response?.data?.data?.actions);
    } finally {
      setLoading(false);
    }
  };

  const handleAction = (actions: any[] = []) => {
    if (!onPress || !actions.length) return;

    onPressAction(actions);
  };

  const handleChangeCursor = (value: string, name: string) => {
    setCursor((prev) => {
      switch (name) {
        case CARD.CARD_NAME:
          return {
            ...prev,
            cursorCardName: value,
          };
        case CARD.CARD_NUMBER:
          return {
            ...prev,
            cursorCardNumber: value,
          };

        default:
          break;
      }
    });
  };

  useEffect(() => {
    if (Platform.OS === 'web') {
      const { cursorCardName } = cursor || {};

      const cardNameInput = cardNameRef?.current;
      const cardNumberInput = cardNumberRef?.current;

      if (cardNameInput)
        cardNameInput?.setSelectionRange(cursorCardName, cursorCardName);

      cardNumberInput?.addEventListener('input', (e: any) => {
        const field = e.target;
        let position = field.selectionEnd;
        const length = field.value.length;

        handleChange(field.value, CARD.CARD_NUMBER);

        field.selectionEnd =
          position +
          (field.value.charAt(position - 1) === ' ' &&
          field.value.charAt(length - 1) === ' '
            ? 1
            : 0);
      });
    }
  }, [cardNameRef, cardNumberRef, cursor.cursorCardName]);

  return {
    ...props,
    loading,
    card,
    uriImage,
    isDisableButton,
    attributes,
    checkTypeCard,
    handleChange,
    handleSubmit,
    cardNameRef,
    cardNumberRef,
    cursor,
    handleChangeCursor,
    submitButtonText,
  };
};

export type Props = ReturnType<typeof useUnivaPaySubscription>;

export default useUnivaPaySubscription;
