import { getListOptions } from '@common/services';
import { IDatabaseColumn, IObj, ISelect } from '@common/types/';
import { AxiosResponse } from 'axios';
import { find, get, isEmpty, isNil, map } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export type useDropdownProps = {
  showDropDown: boolean;
  value: any;
  list: any[];
  handleShowDropdown: () => void;
  handleHiddenDropdown: () => void;
  handleChangeValue: (value: any) => any;
};

const useDropdown = (attributes: ISelect) => {
  const { changeInput, valueInput, dependencies } = attributes;
  const [listOption, setListOption] = useState([]);

  const getLabelField = get(attributes, 'database.options.labelField');
  const isMakeCustom = get(attributes, 'seperate.enabled', false);

  const fieldTable: any[] = [];

  const getFieldTable = find(
    fieldTable,
    (item) => item.databaseUuid === attributes.databaseUuid
  );

  const getFieldId: IDatabaseColumn | any = find(
    getFieldTable?.fields,
    (item) => item.fid === getLabelField
  );

  const [showDropDown, setShowDropDown] = useState(false);

  const handleShowDropdown = () => setShowDropDown(true);
  const handleHiddenDropdown = () => setShowDropDown(false);

  const optionDataOfDatabase = useMemo(() => {
    const defaultValue =
      get(attributes, 'attributes.defaultValue.text') ||
      get(attributes, 'database.options.labelField');

    return map(listOption, (value: any) => {
      return {
        value: value._id || value.id,
        label: value?.[defaultValue],
      };
    }).filter(
      (item: Record<string, any>) => !isNil(item.label) || !isEmpty(item.label)
    );
  }, [listOption, getFieldTable, getFieldId]);

  const optionCustomData = useMemo(() => {
    return get(attributes, 'seperate.items', '')
      .split('\n')
      .map((item: string) => ({
        label: item.trim(),
        value: item.trim(),
      }))
      .filter(
        (item: Record<string, any>) =>
          !isNil(item.label) || !isEmpty(item.label)
      );
  }, [attributes]);

  const list = isMakeCustom ? optionCustomData : optionDataOfDatabase;

  const handleChangeValue = (value: any) => {
    changeInput(value);
  };

  useEffect(() => {
    const getListOption = async (field: IObj) => {
      const params = {
        appId: dependencies.appInfor.id,
        fieldId: field?.fieldId,
        screenUuid: attributes?.screenUuid,
        objectId: attributes?.formId,
        dependencies,
      };

      getListOptions(params)
        .then((resp: AxiosResponse) => {
          setListOption(resp.data);
        })
        .catch(() => {
          setListOption([]);
        });
    };

    if (attributes?.formId && attributes?.field && dependencies?.appInfor) {
      getListOption(attributes?.field);
    } else {
      setListOption(attributes.dataBinding || []);
    }
  }, [attributes]);

  return {
    showDropDown,
    value: valueInput,
    list,
    handleShowDropdown,
    handleHiddenDropdown,
    handleChangeValue,
  };
};

export default useDropdown;
