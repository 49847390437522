import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DimensionObject, IPage } from '@common/types';

export type StatePage = {
  pages: { [key: string]: IPage };
  isHaveDevelopPlugin: boolean;
  isDevelopPluginLoading: boolean;
  dimension: DimensionObject;
  loadingDimension: boolean;
  googleMaploaded: boolean;
  valueBindingScreen: Record<string, any>;
  loadingPage: boolean;
  keysBinding: { [key: string]: string[] };
  bindingLoader: boolean;
};

const initialState: StatePage = {
  pages: {},
  isHaveDevelopPlugin: false,
  isDevelopPluginLoading: false,
  dimension: { fontScale: 0, height: 0, scale: 0, width: 0 },
  loadingDimension: false,
  googleMaploaded: false,
  valueBindingScreen: {},
  loadingPage: false,
  keysBinding: {},
  bindingLoader: true,
};

const pageSlice = createSlice({
  name: 'pageInfo',
  initialState: initialState,
  reducers: {
    loadPages: (
      state: StatePage,
      action: PayloadAction<{ [key: string]: IPage }>
    ) => {
      if (action.payload) {
        state.pages = action.payload;
      }
    },
    loadDevelopPlugin: (
      state: StatePage,
      action: PayloadAction<{ isDevelopPluginLoading: boolean }>
    ) => {
      state.isDevelopPluginLoading = action.payload.isDevelopPluginLoading;
    },
    setHaveDevelopPlugin: (
      state: StatePage,
      action: PayloadAction<{ isHaveDevelopPlugin: boolean }>
    ) => {
      state.isHaveDevelopPlugin = action.payload.isHaveDevelopPlugin;
    },
    setDimensionStore: (
      state: StatePage,
      action: PayloadAction<DimensionObject>
    ) => {
      if (action.payload) {
        state.dimension = action.payload;
        state.loadingDimension = false;
      }
    },
    setLoadingDimension: (state: StatePage, action: PayloadAction<boolean>) => {
      state.loadingDimension = action.payload;
    },
    setGoogleMaploaded: (state: StatePage, action: PayloadAction<boolean>) => {
      state.googleMaploaded = action.payload;
    },
    setValueBindingScreen: (state: StatePage, action: PayloadAction<any>) => {
      state.valueBindingScreen = {
        ...state.valueBindingScreen,
        ...action.payload.data,
      };
      state.keysBinding[action.payload.screenUuid] = Object.keys(
        action.payload.data
      );
    },
    removeValueBindingScreen: (
      state: StatePage,
      action: PayloadAction<any>
    ) => {
      const arrId = state.keysBinding[action.payload] || [];
      if (arrId.length <= 0) return;
      for (let i = 0; i < arrId.length; i++) {
        delete state.valueBindingScreen[arrId[i]];
      }
    },
    setLoadingPage: (state: StatePage, action: PayloadAction<any>) => {
      state.loadingPage = action.payload;
    },
    setBindingLoader: (state: StatePage, action: PayloadAction<any>) => {
      state.bindingLoader = action.payload;
    },
  },
});

export const {
  loadPages,
  setDimensionStore,
  setLoadingDimension,
  loadDevelopPlugin,
  setHaveDevelopPlugin,
  setGoogleMaploaded,
  setValueBindingScreen,
  setLoadingPage,
  removeValueBindingScreen,
  setBindingLoader,
} = pageSlice.actions;
export default pageSlice.reducer;
