import ObjectRender from '@common/screens/RenderScreen/ObjectRender';
import { get, isEmpty, isNil } from 'lodash';
import React from 'react';
import { TouchableOpacity } from 'react-native';

const Item = ({ item, attrs, index }: any) => {
  const { children, rowMargin, databaseOptions, record } = attrs;
  const attrId = get(attrs, 'initId', attrs.id);

  const newChildren = children.map((child: any) => {
    const currentListIds = {
      [databaseOptions?.id]: item?.id,
      [attrId]: item?.id,
      ...(attrs.currentListIds || {}),
    };

    const selectedItem = {
      itemId: item?._id,
      tableId: attrs.databaseUuid,
      itemIndex: index,
      externalId: item?.id,
      externalRecord: item,
    };

    const currentRecordIds = {
      [databaseOptions?.tableId]: item?.id,
      ...(record?.id && {
        [record?._meta?.tableId]: record?.id,
      }),
    };

    const web3Config = {
      ...(item?.web3Config && {
        [databaseOptions?.id]: item?.web3Config,
        [attrId]: item?.web3Config,
        [databaseOptions?.tableId]: item?.web3Config,
      }),
      ...(attrs.web3Config || {}),
      ...(record?.web3Config && {
        [record?._meta?.tableId]: record?.web3Config,
      }),
    };

    const groupActionId = attrs?.actions && Object.keys(attrs.actions)[0];

    return {
      ...child,
      selectedItem,
      groupActionId,
      record: { ...item, listObjectId: attrs.id },
      isInCustomList: true,
      currentListId: attrs.id,
      parentListDatabaseUuid:
        attrs.parentListDatabaseUuid || attrs.databaseUuid,
      parentListItemId: attrs.parentListItemId || attrs.record?._id,
      ...(isNil(child.actions) || isEmpty(child.actions)
        ? { actions: attrs.actions }
        : {
            actions: {
              ...child?.actions,
              ...attrs?.actions,
            },
          }),
      allowListAction: isEmpty(child?.actions) && !isEmpty(groupActionId),
      currentListIds,
      itemIndex: index,
      currentRecordIds,
      web3Config,
      screenUuid: attrs?.screenUuid,
    };
  });

  let sectionGroup: Array<any> = [];
  if (newChildren.length > 1 && newChildren[0].type == 'section') {
    const el = newChildren[0];
    const sectionChildren: Array<any> = [];
    let lastBottomLeft = 0;
    for (let i = 0; i < newChildren.length; i++) {
      if (i == 0) {
        continue;
      } else {
        const o = newChildren[i];
        const currentBottomLeft = o.y + o.height;
        if (o.y >= el.y && currentBottomLeft <= el.y + el.height) {
          sectionChildren.push(o);
          if (currentBottomLeft > lastBottomLeft) {
            lastBottomLeft = currentBottomLeft;
          }
        } else {
          sectionGroup.push(o);
        }
      }
    }
    if (lastBottomLeft) el.paddingBottom = el.y + el.height - lastBottomLeft;
    el.children = sectionChildren;
    sectionGroup.push(el);
  } else {
    sectionGroup = newChildren;
  }

  return (
    <TouchableOpacity
      onPress={(e) => {
        if (!isEmpty(attrs?.actions)) {
          e.preventDefault();
          attrs.onPress &&
            attrs.onPress('', {
              itemId: item._id,
            });
        }
      }}
      style={{
        marginBottom: rowMargin,
      }}
    >
      <ObjectRender
        keyItem={index}
        arrComp={sectionGroup}
        isScreen={false}
        layout={{
          offsetTop: attrs?.y,
          offsetLeft: attrs?.x,
          parentWidth: attrs?.width,
        }}
      />
    </TouchableOpacity>
  );
};

export default Item;
