import { IDatePicker } from '@common/types/element';
import React, { FC, useCallback, useState } from 'react';
import { Platform, Text, TouchableOpacity } from 'react-native';
import { dateConfig, dateTimeConfig } from './helps/config';
import createStyles from './style';

import moment from 'moment';
import DateTimePickerApp from 'react-native-modal-datetime-picker';
import connectorInput from '../ConnectorInput';
import { getDateFormat, OPTIONS_MODEL } from './helps/func';
import { PickerType } from './helps/type';
const DatePickerWeb = require('react-mobile-datepicker');
const DatePickerCustom: any = DateTimePickerApp;

const DatePicker: FC<IDatePicker> = (props) => {
  const PlatformWeb = Platform.OS === 'web';
  const { changeInput, valueInput, defaultDateFormat, defaultDatePickerUI } =
    props;

  const styles = createStyles(props);

  const settings: PickerType = {
    mode: 'date',
    confirmText: 'OK',
    cancelText: 'Cancel',
    theme: 'ios',
    dateFormat: getDateFormat(defaultDateFormat),
  };

  const convertDate = useCallback(
    (params: any) => {
      if (!params) {
        return;
      }
      if (defaultDateFormat === 'dateOnly') {
        return moment(params).format(OPTIONS_MODEL[defaultDateFormat]);
      } else if (defaultDateFormat === 'dateTextInput') {
        return moment(params).format(OPTIONS_MODEL[defaultDateFormat]);
      } else {
        return moment(params).format(OPTIONS_MODEL[defaultDateFormat]);
      }
    },
    [defaultDateFormat]
  );

  const UIdisplay =
    defaultDatePickerUI === 'drumRoll'
      ? 'spinner'
      : Platform.OS === 'ios'
      ? 'inline'
      : 'default';

  const [visible, setVisible] = useState(false);

  const handleShowPicker = () => setVisible(true);
  const handleHidePicker = () => setVisible(false);

  const handleConfirm = (date: any) => {
    changeInput(new Date(date).toISOString());
    handleHidePicker();
  };

  return (
    <React.Fragment>
      <TouchableOpacity
        activeOpacity={1}
        style={[styles.container]}
        onPress={handleShowPicker}
      >
        <Text style={styles.text} numberOfLines={1}>
          {convertDate(valueInput)}
        </Text>

        {PlatformWeb ? (
          <DatePickerWeb
            value={valueInput ? new Date(valueInput) : new Date()}
            min={new Date(1900, 0, 0)}
            max={new Date(2200, 0, 0)}
            isOpen={visible}
            onSelect={handleConfirm}
            onCancel={handleHidePicker}
            confirmText={settings.confirmText}
            cancelText={settings.cancelText}
            theme={settings.theme}
            style={{ color: props.color }}
            dateConfig={
              defaultDateFormat === 'date' ? dateTimeConfig : dateConfig
            }
          />
        ) : (
          <DatePickerCustom
            isVisible={visible}
            mode={defaultDateFormat === 'date' ? 'datetime' : 'date'}
            minimumDate={new Date(1900, 0, 0)}
            maximumDate={new Date(2200, 0, 0)}
            onConfirm={handleConfirm}
            onCancel={handleHidePicker}
            display={UIdisplay}
            cancelTextIOS="キャンセル"
            confirmTextIOS="OK"
          />
        )}
      </TouchableOpacity>
    </React.Fragment>
  );
};

export default connectorInput(DatePicker);
