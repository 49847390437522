import { dataBindingType } from '@common/constants/shared';
import { isEmpty } from 'lodash';

export const getSourceDataBinding = (obj: any): any => {
  if (isEmpty(obj)) return {};

  let res: any = {};
  for (var k in obj) {
    const value = obj[k];
    if (dataBindingType.includes(k) && value?.type === 'binding') {
      res = value;
    } else if (typeof value === 'object') {
      res = { ...res, ...getSourceDataBinding(value) };
    }
  }
  return res;
};
