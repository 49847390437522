import { PaymentIntent, PaymentMethodResult } from '@stripe/stripe-js';
import { get, isEmpty } from 'lodash';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import { stripeMessage } from '../locale';
import { convertTextAttributes, getValueBinding } from '../shared';
import { CardStripeInfo, IStripePaymentSecurity } from './style';

export const useStripeSecurity = (props: IStripePaymentSecurity) => {
  const {
    attributes,
    onPress,
    accountConnectId,
    publicKey: _publicKey,
    updateRecordRecordApi,
    handlePayment: _handlePayment,
    auth,
    locale,
  } = props;

  const bindingOptions = getValueBinding(props.id, props.data, props);

  const {
    paymentOptions,
    rememberCheckbox,
    submitButton,
    loading: loadingActions,
  } = attributes;

  const testMode = {
    enabled: get(attributes, 'testMode.enabled'),
    publishableKey: get(bindingOptions, 'testMode.publishableKey'),
    secretKey: get(bindingOptions, 'testMode.secretKey'),
  };

  const cardSaved = useMemo(
    () => get(props.auth, rememberCheckbox.cardTokenSaved, ''),
    [rememberCheckbox.cardTokenSaved, props.auth]
  );

  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};
  const target = search?.target;
  const isCanvas = Platform.OS === 'web' && !target;

  const [publicKey, setPublicKey] = useState(_publicKey);

  const [_loading, setLoading] = useState<boolean>(false);
  const [getCardLoading, setGetCardLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<{ success?: string; error?: string }>(
    {}
  );
  const [rememberCard, setRememberCard] = useState(false);

  const [cardDefaultInfo, setCardDefaultInfo] = useState<CardStripeInfo | null>(
    null
  );

  const isSaveCardAction = useMemo(() => {
    return submitButton.type === 'saveCard';
  }, [submitButton.type]);

  const loading = useMemo(() => {
    return loadingActions || _loading || getCardLoading;
  }, [loadingActions, _loading, getCardLoading]);

  const isTestMode = () => {
    return testMode.enabled && testMode.publishableKey && testMode.secretKey;
  };

  const isValidTestMod = () => {
    return (
      testMode.secretKey.startsWith('sk_test') &&
      testMode.publishableKey.startsWith('pk_test')
    );
  };

  const onClickCheckBox = () => {
    setRememberCard((value) => !value);
  };

  const handleAction = async (id: string, data: any = {}) => {
    const actionId = get(attributes, `${id}.actionId`, '');
    const arrayAction = get(props, `actions.${actionId}.actions`, []);
    return await onPress(arrayAction, data);
  };

  useEffect(() => {
    if (isCanvas) {
      return;
    }
    setMessage({});
    if (isTestMode() && !isValidTestMod()) {
      setMessage({
        error: stripeMessage({
          locale,
          type: 'stripe_configured',
        }),
      });
      return;
    }
    if (isTestMode() && isValidTestMod()) {
      setPublicKey(testMode.publishableKey);
    }

    const getCardInfo = async () => {
      if (isCanvas) {
        return;
      }
      if (isTestMode() && !isValidTestMod()) {
        return;
      }

      const validCardTokenSaved = cardSaved && cardSaved.startsWith('pm_');
      const isRememberCard = rememberCheckbox.enabled && validCardTokenSaved;
      if (!isRememberCard) {
        setCardDefaultInfo(null);
      }
      if (validCardTokenSaved) {
        const res = await _handlePayment({
          type: 'get-card-info',
          paymentMethod: cardSaved,
          secretKey: isTestMode() ? testMode.secretKey : undefined,
          stripeAccount: accountConnectId || '',
        });
        const card = res as CardStripeInfo;
        if (card?.brand) {
          setCardDefaultInfo(card);
        }
      }
    };
    setGetCardLoading(true);
    getCardInfo()
      .catch((error) => {
        setMessage({
          error: `${error.message}`,
        });
      })
      .finally(() => {
        return setGetCardLoading(false);
      });
  }, [rememberCheckbox.enabled, cardSaved]);

  const handleSaveCard = async ({
    paymentMethod,
    cardInfo,
  }: {
    paymentMethod: string;
    cardInfo: CardStripeInfo;
  }) => {
    if (!rememberCard || isEmpty(rememberCheckbox.cardTokenSaved)) return;
    const payload = {
      [rememberCheckbox.cardTokenSaved]: paymentMethod,
    };
    await updateRecordRecordApi({
      databaseId: auth.databaseId,
      record: payload,
      recordId: auth.id,
      dependencies: props.dependencies,
    });
    setCardDefaultInfo(cardInfo);
  };

  const handleCheckStatusPaymentIntent = async (status: string) => {
    switch (status) {
      case 'succeeded':
        setMessage({
          success: stripeMessage({ locale, type: 'success' }),
        });
        await handleAction('successActions');
        break;

      case 'processing':
        setMessage({
          success: stripeMessage({ locale, type: 'processing' }),
        });
        await handleAction('successActions');
        break;

      case 'requires_payment_method':
        setMessage({
          error: stripeMessage({ locale, type: 'requires_payment_method' }),
        });
        await handleAction('failedActions');
        break;

      default:
        setMessage({
          error: stripeMessage({ locale, type: 'requires_payment_method' }),
        });
        await handleAction('failedActions');
        break;
    }
  };

  const createPayment = async ({
    payload,
    paymentMethodId,
  }: any): Promise<{ paymentIntent?: PaymentIntent; success: boolean }> => {
    const response = await handleAction('beforeActions', {
      record: {
        ...payload,
        paymentMethodId,
      },
    });

    const purchaseResponse = response.find(
      ({ actionType }: { actionType: string }) =>
        actionType === 'purchase_product_all'
    );

    if (!purchaseResponse) {
      const failure = [...(response || [])].find(
        (action) => action.status !== 'SUCCEED'
      );
      if (failure) {
        await handleAction('failedActions');
        return {
          success: false,
        };
      }
      const res = (await _handlePayment({
        type: 'create-payment',
        paymentMethodId,
        ...payload,
      })) as { paymentIntent: PaymentIntent };
      return {
        paymentIntent: res?.paymentIntent,
        success: true,
      };
    }

    if (purchaseResponse?.status === 'SUCCEED') {
      setMessage({
        success: stripeMessage({ locale, type: 'success' }),
      });
      await handleAction('successActions');
      return {
        paymentIntent: purchaseResponse.paymentIntent,
        success: true,
      };
    }
    if (purchaseResponse?.paymentIntent) {
      return {
        paymentIntent: purchaseResponse?.paymentIntent,
        success: false,
      };
    }
    await handleAction('failedActions');
    return {
      success: false,
    };
  };
  const handlePayment = async (
    handleCreatePaymentMethod: () => Promise<PaymentMethodResult | undefined>
  ) => {
    try {
      setLoading(true);
      const payload = {
        amount: get(bindingOptions, 'paymentOptions.paymentAmount'),
        currency: paymentOptions.typeCurrency,
        receipt_email: get(bindingOptions, 'email.emailBuyer'),
        description: get(bindingOptions, 'changedescription.description'),
        destination: accountConnectId,
        testMode,
      };
      if (
        cardDefaultInfo?.brand &&
        rememberCheckbox.enabled &&
        rememberCheckbox.cardTokenSaved &&
        !isSaveCardAction
      ) {
        const cardTokenSaved = get(
          props?.data,
          `${props?.id}.rememberCheckbox.cardTokenSaved`,
          convertTextAttributes(
            get(props.attributes, 'rememberCheckbox.cardTokenSaved')
          )
        );
        const getAuthPaymentMethodId = auth[cardTokenSaved];
        const { paymentIntent, success } = await createPayment({
          payload,
          paymentMethodId: getAuthPaymentMethodId,
        });

        if (!success) {
          return;
        }
        paymentIntent?.status &&
          (await handleCheckStatusPaymentIntent(paymentIntent!.status));
        return;
      }
      const { paymentMethod, error } =
        (await handleCreatePaymentMethod()) || {};

      if (error) {
        setMessage({
          error: stripeMessage({ locale, type: error?.message }),
        });
        return;
      }
      await handleSaveCard({
        paymentMethod: paymentMethod!.id,
        cardInfo: paymentMethod!.card!,
      });
      if (isSaveCardAction) {
        return;
      }
      const { paymentIntent, success } = await createPayment({
        payload,
        paymentMethodId: paymentMethod!.id,
      });
      if (!success) {
        return;
      }
      paymentIntent?.status &&
        (await handleCheckStatusPaymentIntent(paymentIntent!.status));
    } catch (e: any) {
      setMessage({
        error: stripeMessage({ locale, type: e?.message }),
      });
      await handleAction('failedActions');
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentMobile = async (
    paymentMethodId: string,
    confirmPayment: (
      client_secret: string
    ) => Promise<{ paymentIntent?: any; error?: any }>
  ) => {
    try {
      if (isSaveCardAction) {
        return;
      }
      const payload = {
        amount: get(bindingOptions, 'paymentOptions.paymentAmount'),
        currency: paymentOptions.typeCurrency,
        receipt_email: get(bindingOptions, 'email.emailBuyer'),
        description: get(bindingOptions, 'changedescription.description'),
        destination: accountConnectId,
        testMode,
      };
      const { paymentIntent, success } = await createPayment({
        payload,
        paymentMethodId: paymentMethodId.startsWith('pm')
          ? paymentMethodId
          : auth[paymentMethodId],
      });

      if (!paymentIntent) {
        return;
      }

      if (paymentIntent?.status === 'succeeded') {
        setMessage({
          success: stripeMessage({ locale, type: 'success' }),
        });
        await handleAction('successActions');
        return;
      }

      // // Confirm the payment with the card details
      const { paymentIntent: paymentIntentConfirm, error } =
        await confirmPayment(paymentIntent!.client_secret!);

      if (error) {
        setMessage({
          error: stripeMessage({
            locale,
            type: error?.localizedMessage || error?.message,
          }),
        });
        await handleAction('failedActions');
        return;
      } else if (paymentIntent) {
        paymentIntentConfirm?.status &&
          (await handleCheckStatusPaymentIntent(paymentIntentConfirm.status));
      }
    } catch (e: any) {
      setMessage({
        error: stripeMessage({ locale, type: e?.message }),
      });
      await handleAction('failedActions');
    } finally {
    }
  };

  return {
    isTestMode,
    isValidTestMod,
    publicKey,
    loading,
    setLoading,
    message,
    setMessage,
    rememberCard,
    handleAction,
    cardDefaultInfo,
    onClickCheckBox,
    handlePayment,
    setCardDefaultInfo,
    handleSaveCard,
    handlePaymentMobile,
    isSaveCardAction,
  };
};
