import { IMap } from '@common/types/element';
import {
  GoogleMap,
  LoadScript,
  Marker,
  MarkerClusterer,
  useLoadScript,
} from '@react-google-maps/api';
import * as _ from 'lodash';
import queryString from 'query-string';
import React, {
  memo,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ActivityIndicator, Image, TouchableOpacity, View } from 'react-native';
import { getActions, getItemListClick } from '../shared';
import { defaultImageMarker, DEFAULT_ZOOM } from './constans';
import { mapImgInCanvas } from './constans/images';
import currentLocationIcon from './constans/images/currentLocation.png';
import roadmap from './constans/images/roadmap.png';
import selectedMarkerIcon from './constans/images/selectedMarker.png';
import createStyles from './constans/styles';
import { convertKmToM, stringToJson } from './functions';
import useLocation from './customHook/useGetPosition/index.web';
import useMarker from './customHook/useMarker';
import useWatchRealtime from './customHook/useWatchPosition/index.web';

const Map: React.FC<IMap> = (props) => {
  const search = queryString.parse(window?.location?.search);
  const target = search?.target;
  const mapType = props?.attributes?.style?.mapStyle || 'roadmap';

  /////canvas
  const mapImage = mapImgInCanvas[mapType];
  if (!target)
    return (
      <Image
        style={{
          width: props.width,
          height: props.height,
        }}
        source={mapImage || roadmap}
      />
    );

  /////preview
  const { initializeList, onPress, attributes } = props;
  let clusterRef: any = useRef();
  const styles = createStyles(props);

  const [mapState, setMapState] = useState<any>(null);

  const getCurrentUserMaker = (clusterRef: any) => {
    const markers = clusterRef.getMarkers() || [];
    return _.find(markers, (item) => item?.icon?.isCurrentLocation);
  };

  const {
    markers,
    centerLocation,
    selectedMaker,
    showCurrentLocation,
    imagePlaceholder,
    closeMarkers,
    distanceToSearch,
    handledDistance,
  } = useMarker(props);

  const { currentLocation, loaded: loadedCurrentLocation } = useLocation();

  const handlePress = useCallback(() => {
    if (mapState && clusterRef.current) {
      const currentUserMarker = getCurrentUserMaker(clusterRef.current);
      if (_.isEmpty(currentUserMarker)) return;
      const userPosition = currentUserMarker.getPosition();
      mapState.setCenter(userPosition);
      mapState?.setZoom(DEFAULT_ZOOM);
    }
  }, [clusterRef, mapState]);

  const handleClickItem = useCallback(
    (marker: any) => () => {
      if (marker?.isCurrentLocation || marker?.center) return;
      const options = {
        itemListClick: getItemListClick(marker.record),
      };

      onPress && onPress(getActions(props, 'onPress'), options);
    },
    [props.id, attributes]
  );

  const markerRenderer = useMemo(() => {
    return [
      ...markers,
      ...(showCurrentLocation && !_.isEmpty(currentLocation)
        ? [
            {
              ...currentLocation,
              imageMarker: {
                url: currentLocationIcon,
              },
              scaledSize: window?.google
                ? new window.google.maps.Size(30, 30)
                : undefined,
              origin: window.google
                ? new window.google.maps.Point(0, 0)
                : undefined,
              anchor: window.google
                ? new window.google.maps.Point(15, 15)
                : undefined,
              isCurrentLocation: true,
            },
          ]
        : []),
    ];
  }, [markers, showCurrentLocation, currentLocation]);

  useLayoutEffect(() => {
    if (mapState) {
      const Bounds = new window.google.maps.LatLngBounds();
      if (
        (!_.isUndefined(currentLocation) && showCurrentLocation) ||
        selectedMaker
      ) {
        !selectedMaker && Bounds.extend(currentLocation);
        selectedMaker && Bounds.extend(selectedMaker);
        if (selectedMaker) {
          if (props.attributes.markerType === 'multiple') {
            if (closeMarkers.length > 1) {
              // closeMarkers > 0
              closeMarkers.map((closeMarker) => {
                Bounds.extend(closeMarker);
              });
              mapState.fitBounds(Bounds);
            } else {
              // closeMarkers < 0
              const centerMarker = _.find(markers, 'center');

              if (centerMarker) {
                var myLatLng = new google.maps.LatLng({
                  lat: centerMarker?.lat,
                  lng: centerMarker?.lng,
                });
                const radiusZoom = convertKmToM(distanceToSearch) / 2;
                var circleOptions = {
                  center: myLatLng,
                  fillOpacity: 0,
                  strokeOpacity: 0,
                  map: mapState,
                  radius: radiusZoom /*  meters */,
                };
                var myCircle = new google.maps.Circle(circleOptions);
                mapState.fitBounds(myCircle.getBounds());
              }
            }
          } else {
            return;
          }
        } else {
          if (props.attributes.markerType === 'multiple') {
            if (closeMarkers.length > 0) {
              //closeMarkers > 0
              closeMarkers.map((closeMarker) => {
                Bounds.extend(closeMarker);
              });
              mapState.fitBounds(Bounds);
            } else {
              //closeMarkers < 0
              if (location) {
                var myLatLng = new google.maps.LatLng({
                  lat: currentLocation.lat,
                  lng: currentLocation.lng,
                });
                const radiusZoom = convertKmToM(distanceToSearch) / 2;
                var circleOptions = {
                  center: myLatLng,
                  fillOpacity: 0,
                  strokeOpacity: 0,
                  map: mapState,
                  radius: radiusZoom /*  meters */,
                };
                var myCircle = new google.maps.Circle(circleOptions);
                mapState.fitBounds(myCircle.getBounds());
              }
            }
          } else if (
            markers.length > 0 &&
            props.attributes.markerType === 'simple'
          ) {
            markers.map((marker) => {
              Bounds.extend(marker);
            });
            mapState.fitBounds(Bounds);
          } else {
            return;
          }
        }
      } else if (
        (_.isUndefined(location) || !showCurrentLocation) &&
        !selectedMaker &&
        markers.length > 1
      ) {
        markers.map((marker) => {
          Bounds.extend(marker);
        });
        mapState.fitBounds(Bounds);
      } else {
        return;
      }
    }
  }, [
    mapState,
    markers,
    showCurrentLocation,
    location,
    handledDistance,
    closeMarkers,
  ]);

  useWatchRealtime(clusterRef, showCurrentLocation, getCurrentUserMaker);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: props.attributes?.apiKey,
  });

  if (!loadedCurrentLocation || !isLoaded || initializeList) {
    return (
      <View style={styles.placeholder}>
        <ActivityIndicator />
      </View>
    );
  }

  return (
    <GoogleMap
      mapContainerStyle={_.pick(props, ['width', 'height'])}
      options={{
        zoom: DEFAULT_ZOOM,
        center: centerLocation,
        panControl: true,
        disableDefaultUI: true,
        zoomControl: true,
        mapTypeId: mapType,
        styles: stringToJson(_.get(props, 'attributes.style.customStyle')),
      }}
      onLoad={(map) => {
        setMapState(map);
      }}
    >
      <>
        {/* Child components, such as markers, info windows, etc. */}
        {showCurrentLocation && currentLocation && (
          <TouchableOpacity
            style={styles.currentLocationButton}
            onPress={handlePress}
          >
            <Image
              style={styles.currentImage}
              source={{
                uri: 'https://cdn2.iconfinder.com/data/icons/boxicons-regular-vol-2/24/bx-current-location-256.png',
              }}
            />
          </TouchableOpacity>
        )}
        <MarkerClusterer
          options={{
            imagePath:
              'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
          }}
          onLoad={(clusterState: any) => {
            clusterRef.current = clusterState;
          }}
        >
          {(cluster) => {
            return (
              <>
                {markerRenderer.map((marker: any, index) => {
                  const imageMarker = marker.imageMarker;

                  const imageUrl =
                    typeof imageMarker === 'object'
                      ? imageMarker?.url
                      : imageMarker;

                  const placeholder =
                    imagePlaceholder?.placeholderImageEnabled &&
                    !_.isEmpty(imagePlaceholder?.placeholderImage)
                      ? imagePlaceholder?.placeholderImage
                      : defaultImageMarker;

                  const iconUrl = !_.isEmpty(imageUrl) ? imageUrl : placeholder;

                  const icon = {
                    url: iconUrl,
                    scaledSize: marker?.scaledSize
                      ? marker?.scaledSize
                      : new google.maps.Size(35, 35), // scaled size
                    ...(marker?.origin ? { origin: marker.origin } : {}),
                    ...(marker?.anchor ? { anchor: marker.anchor } : {}),
                    isCurrentLocation: marker?.isCurrentLocation || false,
                  };

                  return (
                    <React.Fragment key={index}>
                      <Marker
                        clusterer={cluster}
                        position={marker}
                        onClick={handleClickItem(marker)}
                        icon={marker.center ? selectedMarkerIcon : icon}
                      />
                    </React.Fragment>
                  );
                })}
              </>
            );
          }}
        </MarkerClusterer>
      </>
    </GoogleMap>
  );
};

export default Map;
