import React from 'react';
import { Router, Redirect, Route, Switch } from 'react-router-native';
// import ShareScreen from '../screens/ShareScreen';
import RenderScreen from '../screens/RenderScreen';
import history from './history';
import appConfig from '../../appConfig.json';

export default function AppRoutes() {
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Redirect to={`/preview/${appConfig.appId}`} />}
        />
        <Route path="/preview/:appId" component={RenderScreen} />
      </Switch>
    </Router>
  );
}
