import AnimationWrapper from '@common/components/Animation/NormalAnimation';
import { resetPassword } from '@common/redux/selectors/action';
import { appInfoSelector } from '@common/redux/selectors/app';
import { profileSelector } from '@common/redux/selectors/auth';
import { appLanguageSelector } from '@common/redux/selectors/language';
import {
  setForgotPassword,
  setResetPassword,
} from '@common/redux/slice/action';
import { get } from 'lodash';
import React, { Fragment, useState } from 'react';
import { Animated, Text, TextInput, View } from 'react-native';
import { Button as RNButton } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { convertMessage, handleResetPassword } from './func';
import createStyles from './style';
import { isEmpty } from 'lodash';

ResetPasswordWrap.propTypes = {};

function ResetPasswordWrap() {
  const dispatch = useDispatch();

  const profile = useSelector(profileSelector);
  const resetPasswordSelector = useSelector(resetPassword);
  const { locale } = useSelector(appLanguageSelector);
  const appInfo = useSelector(appInfoSelector);

  const styles = createStyles(appInfo);

  const isJa = locale === 'ja';

  const [value, setValue] = useState<string>('');
  const [response, setResponse] = useState<any>({
    status: false,
    loading: false,
    error: '',
  });

  const onSubmit = async () => {
    try {
      if (!appInfo) return;

      if (isEmpty(value)) {
        setResponse({
          ...response,
          error: 'new password is not allowed to be empty',
        });
        return;
      }

      setResponse({
        ...response,
        loading: true,
      });

      const res = await handleResetPassword({
        appId: appInfo?.id,
        email: profile?.email,
        value,
      });

      if (res) {
        dispatch(
          setResetPassword({ state: false, animation: 'fadeOutDownBig' })
        );
        dispatch(
          setForgotPassword({
            state: false,
            animation: 'fadeOutDownBig',
          })
        );
      }

      setResponse({
        ...response,
        status: !!res,
        loading: false,
      });
    } catch (err: any) {
      setResponse({
        ...response,
        error: get(err, 'response')
          ? 'new password is not allowed to be empty'
          : get(err, 'response.data.errors.email', ''),
      });
      console.log('err :>> ', err);
    }
  };

  const onCancel = () => {
    dispatch(setResetPassword({ state: false, animation: 'fadeOutDownBig' }));
    setResponse({
      ...response,
      error: '',
    });
  };

  if (!resetPasswordSelector.state || !appInfo) return <Fragment />;

  return (
    <Animated.View
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        backgroundColor: '#fff',
      }}
    >
      <AnimationWrapper animation={resetPasswordSelector.animation}>
        <View
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            height: '100%',
          }}
        >
          <View style={styles.wrapper}>
            <Text style={styles.title}>
              {isJa ? '新しいパスワードの作成' : 'Create New Password'}
            </Text>
            <Text style={styles.desc}>
              {isJa
                ? '仮パスワードで正常にログインしました。新しいパスワードを再設定してください。'
                : `You've successfully logged in with your temporary password. Please create a new permanent password for your account.`}
            </Text>

            <Text style={styles.text}>
              {isJa ? '新しいパスワード' : 'New Pasword'}
            </Text>
            <TextInput
              style={styles.input}
              onChangeText={(e) => setValue(e)}
              placeholder={
                isJa
                  ? '新しいパスワードを入力してください'
                  : 'Enter new password...'
              }
              placeholderTextColor="#777777"
              secureTextEntry
            />
            <Text style={styles.error}>
              {convertMessage({ message: response.error, locale: locale })}
            </Text>

            <RNButton
              onPress={onSubmit}
              labelStyle={{
                color: '#fff',
                fontSize: 14,
              }}
              style={{
                backgroundColor: response.loading ? '#BDBDBD' : '#34A0DD',
                borderRadius: 4,
                minHeight: 40,
                justifyContent: 'center',
                marginTop: 16,
              }}
              disabled={response.loading}
              loading={response.loading}
            >
              {isJa ? '送信' : 'Submit'}
            </RNButton>
            <RNButton
              onPress={onCancel}
              labelStyle={{
                fontSize: 14,
                color: '#777777',
              }}
              style={{
                borderRadius: 4,
                minHeight: 40,
                justifyContent: 'center',
                marginTop: 5,
              }}
            >
              {isJa ? 'キャンセル' : 'Cancel'}
            </RNButton>
          </View>
        </View>
      </AnimationWrapper>
    </Animated.View>
  );
}

export default ResetPasswordWrap;
