import React, { useMemo, memo, useEffect, useCallback } from 'react';
import { BackHandler, Image, StyleSheet, Text, View } from 'react-native';
import { useSelector } from 'react-redux';

import Loading from '@common/components/Loading';
import useAppDetail from '@common/hooks/app';
import { getAllPages } from '@common/hooks/page';
import useSocketConnection from '@common/hooks/useSocketAuthentication';
import { initializeAdmob } from '@common/hooks/admod';
import { dimensionSelector } from '@common/redux/selectors/page';
import { useParams } from '@common/routes/hooks';
import { pastLocationSelector } from '@common/redux/selectors/locationHistory';
import history from '@common/routes/history';

import RenderScreen from './RenderScreen';
import useLocation from '@common/hooks/useLocation';
import useWatchRecords from '@common/hooks/useWatchRecord';

const MemoRender = memo(function RenderLayout({ appId }: { appId: string }) {
  const dimension = useSelector(dimensionSelector);
  const pastLocations = useSelector(pastLocationSelector);

  const handleDeviceBack = useCallback(() => {
    if (pastLocations.length <= 1) {
      BackHandler.exitApp();
    }
    history.goBack();
    return true;
  }, [pastLocations]);

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', handleDeviceBack);

    return () => {
      BackHandler.removeEventListener('hardwareBackPress', handleDeviceBack);
    };
  }, [handleDeviceBack]);

  const { data: appInfo, isLoading: loadingApp } = useAppDetail({
    appId,
  });

  const {
    data: pages,
    error,
    isLoading: loadingPage,
    isUseMap,
  } = getAllPages(appId, appInfo);

  initializeAdmob({ appInfo });

  const { isReady: initializedMap }: any = useLocation(isUseMap);

  // Listening to event Socket
  useWatchRecords();

  const isLoadingApp = loadingApp || loadingPage || !initializedMap;
  if (isLoadingApp) return <Loading />;

  const handleRenderScreen = () => {
    if (appInfo && pages && pages.length > 0) {
      return <RenderScreen appInfo={appInfo} />;
    } else {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            width: dimension.width,
            height: dimension.height,
            backgroundColor: 'white',
          }}
        >
          <Image
            source={require('@common/assets/images/splashScreen.png')}
            style={{ width: 200, height: 200 }}
          />
        </View>
      );
    }
  };

  return (
    <React.Fragment>
      {handleRenderScreen()}
      {error && (
        <View style={styles.errorWrapper}>
          <Text style={styles.errorText}>{error}</Text>
        </View>
      )}
    </React.Fragment>
  );
});

const styles = StyleSheet.create({
  errorWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorText: {
    color: 'red',
  },
});

function Render() {
  const params = useParams();
  const appId = useMemo(() => params?.appId, [params?.appId]);
  const { loading: reAuthLoading } = useSocketConnection();

  if (!appId) {
    return (
      <View>
        <Text>Cannot found page</Text>
      </View>
    );
  }

  // authentication SocketIO (forced to run first !)
  if (reAuthLoading) {
    console.log('reAuthLoading');
    return <Loading />;
  }

  return <MemoRender appId={appId} />;
}

export default Render;
