import { APP_API_TOKEN, TOKEN_KEY } from '@common/constants/shared';
import auth, {
  AuthenticationClient,
  AuthenticationClientOptions,
  Storage,
} from '@feathersjs/authentication-client';
import {
  AuthenticationRequest,
  AuthenticationResult,
} from '@feathersjs/authentication/lib';

import feathers, { Application } from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import AsyncStorage from '@react-native-async-storage/async-storage';
import io from 'socket.io-client';
import appConfig from '../../../appConfig.json';
import { Platform } from 'react-native';

let appId: string;
if (Platform.OS === 'web') {
  appId = window.location.pathname
    .split('/')
    .filter((path) => path && path !== 'preview')[0];
} else {
  appId = appConfig.appId;
}

const socket = io(appConfig.baseUrl, {
  query: { appId: appId },
});
const app = feathers();

class MyAuthenticationClient extends AuthenticationClient {
  appId: string;
  constructor(app: Application, options: AuthenticationClientOptions) {
    super(app, options);

    if (Platform.OS === 'web') {
      this.appId = window.location.pathname
        .split('/')
        .filter((path) => path && path !== 'preview')[0];
    } else {
      this.appId = appConfig.appId;
    }
  }
  get storage(): Storage {
    return {
      getItem: (key: string) =>
        AsyncStorage.getItem && AsyncStorage.getItem(key),
      setItem: (key: string, value: any) => {
        AsyncStorage.setItem && AsyncStorage.setItem(key, value ?? '');
      },
      removeItem: (key: string) =>
        AsyncStorage.removeItem && AsyncStorage.removeItem(key),
    };
  }

  async getAccessToken() {
    const token = await this.storage.getItem(TOKEN_KEY);
    return token || APP_API_TOKEN;
  }

  async setAccessToken(accessToken: string) {
    return (
      this.storage.setItem &&
      this.storage.setItem(TOKEN_KEY, accessToken || APP_API_TOKEN)
    );
  }

  async authenticate(
    authentication?: AuthenticationRequest,
    params?: feathers.Params
  ): Promise<AuthenticationResult> {
    const newParams = {
      ...params,
      query: { appId },
    };

    return super.authenticate(authentication, newParams);
  }

  async logout(): Promise<AuthenticationResult | null> {
    return this.authenticate({ strategy: 'jwt', accessToken: APP_API_TOKEN });
  }

  async reAuthenticate(
    force?: boolean | undefined,
    strategy?: string | undefined
  ): Promise<AuthenticationResult> {
    return super.reAuthenticate(force, strategy);
  }
}

// Setup the transport (Rest, Socket, etc.) here
app.configure(
  socketio(socket, {
    timeout: 30000,
  })
);

// Pass the custom authentication client class as the `Authentication` option
app.configure(
  auth({
    Authentication: MyAuthenticationClient,
    storage: AsyncStorage,
    storageKey: TOKEN_KEY,
    path: 'v3/database/authmanagement',
  })
);

app.hooks({
  error(context) {
    const { error } = context;

    if (error.code === 401) {
      app.emit('authFailure', error);
    }

    return context;
  },
});

export default app;
