import React, { Suspense, useCallback, useEffect } from 'react';
import { Dimensions, NativeModules, Platform } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import AppRoutes from './app';
import WebRoutes from './web';

import AppLocale from '@common/configs/translation';
import { appLanguageSelector } from '@common/redux/selectors/language';
import { setLanguage } from '@common/redux/slice/language';
import {
  setDimensionStore,
  setLoadingDimension,
} from '@common/redux/slice/page';
import FCMComponent from '@common/utils/FCM/FCMComponent';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { useLayoutEffect } from 'react';
import { IntlProvider } from 'react-intl';

export function Routes() {
  const dispatch = useDispatch();
  const windowSize = Dimensions.get('window');

  const languageSetting = useSelector(appLanguageSelector);
  const currentAppLocale = AppLocale[languageSetting.locale] || AppLocale.ja;

  useEffect(() => {
    if (windowSize) {
      dispatch(setDimensionStore(windowSize));
    }
  }, [dispatch, windowSize]);

  const onChange = useCallback(
    ({ window }: any) => {
      dispatch(setLoadingDimension(true));
      if (window) {
        dispatch(setDimensionStore(window));
      }
    },
    [dispatch]
  );

  // TODO:
  useEffect(() => {
    Dimensions.addEventListener('change', onChange);
    return () => {
      Dimensions.removeEventListener('change', onChange);
    };
  }, [onChange]);

  const checkLocale = (systemLocale: string) =>
    systemLocale.includes('ja') ? 'ja' : 'en';

  useLayoutEffect(() => {
    try {
      let locale = 'ja';

      if (Platform.OS === 'web') {
        const search = !isEmpty(window)
          ? queryString.parse(window?.location?.search)
          : {};

        if (!search.locale || typeof search.locale !== 'string') return;
        locale = search.locale;
      } else if (Platform.OS === 'ios') {
        const systemIosLocale = checkLocale(
          NativeModules.SettingsManager.settings.AppleLocale
        );

        locale = systemIosLocale;
      } else {
        locale = checkLocale(NativeModules.I18nManager.localeIdentifier);
      }

      dispatch(setLanguage(locale));
    } catch (error: any) {
      console.log('language error: ', error.message);
    }
  }, [dispatch]);

  return (
    <IntlProvider
      locale={currentAppLocale?.locale || currentAppLocale}
      messages={currentAppLocale.messages}
    >
      <Suspense fallback={null}>
        <SafeAreaProvider>
          {Platform.OS === 'web' ? (
            <WebRoutes />
          ) : (
            <FCMComponent>
              <AppRoutes />
            </FCMComponent>
          )}
        </SafeAreaProvider>
      </Suspense>
    </IntlProvider>
  );
}
