import Loading from '@common/components/Loading';
import { bannerFullScreen } from '@common/constants/admob';
import { admobSelector } from '@common/redux/selectors/admob';
import ObjectRender from '@common/screens/RenderScreen/ObjectRender';
import { IRecord } from '@common/types/';
import { IList } from '@common/types/element';
import { getValueBindingComponent } from '@common/utils/component';
import { get, isEmpty } from 'lodash';
import React, { FC, useMemo, useState } from 'react';
import {
  Dimensions,
  FlatList,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useSelector } from 'react-redux';
import EmptyList from '../EmptyList';
import Item from './item';
import createStyle from './style';

const CusList: FC<IList> = (attrs) => {
  const {
    children,
    rowMargin,
    admobs,
    dataBinding,
    page,
    totalPage,
    onLoadMore,
    initializeList,
    isLoadMore,
  } = attrs;
  const styles = createStyle(attrs, dataBinding);
  const tableId = get(attrs, 'databaseOptions.tableId');
  const horizontalScroll = get(
    attrs,
    'databaseOptions.options.horizontalScroll'
  );
  const { width } = Dimensions.get('screen');

  const [contentSizeWidth, setContentSizeWidth] = useState(0);

  const textEmpty = useMemo(
    () => getValueBindingComponent(attrs, 'defaultText'),
    [attrs]
  );
  const { isUseAdmob } = useSelector(admobSelector);

  const renderTextEmpty = useMemo(() => {
    return (
      <View
        style={[
          styles.cusListWrapper,
          {
            alignItems: 'center',
            justifyContent: 'center',
          },
        ]}
      >
        <Text style={{ fontSize: 22, fontWeight: '500' }}>{textEmpty}</Text>
      </View>
    );
  }, [styles.cusListWrapper, textEmpty]);

  // User cannot select table
  if (isEmpty(tableId)) {
    return (
      <View style={styles.emptyListWrapper}>
        <EmptyList attributes={attrs} />
      </View>
    );
  }

  if (initializeList) {
    return <Loading />;
  }

  if (dataBinding.length <= 0) return renderTextEmpty;

  const paginateArr = get(attrs, 'databaseOptions.options.paginate', false);

  if (children.length == 0) return renderTextEmpty;

  const getAdmobComponent = (
    index: number,
    attrs: React.PropsWithChildren<IList>
  ) => {
    if (isUseAdmob && admobs?.length) {
      const admobListComponent = [];
      for (let adIndex = 0; adIndex < admobs.length; adIndex++) {
        if (Platform.OS === 'web' && !admobs[adIndex].placeholder) {
          break;
        }

        if (
          index !== undefined &&
          (index + 1) % admobs[adIndex].perItem === 0
        ) {
          let width: number = 0,
            height: number = 0;
          const isAdFullScreen = bannerFullScreen.includes(
            admobs[adIndex].bannerSize
          );
          if (!isAdFullScreen) {
            const columnCount = attrs.columnCount || 1;
            width = admobs[adIndex].width / columnCount;
            height = (admobs[adIndex].height / admobs[adIndex].width) * width;
          }

          admobListComponent.push(
            <ObjectRender
              keyItem={index}
              arrComp={[
                {
                  ...admobs[adIndex],
                  width,
                  height,
                  hidden: isAdFullScreen,
                },
              ]}
              isScreen={false}
            />
          );
        }
      }

      return (
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: rowMargin,
          }}
        >
          {admobListComponent}
        </View>
      );
    }
    return null;
  };

  const ListFooterComponent = () => {
    return (
      <TouchableOpacity
        onPress={() => {
          if (totalPage > page) {
            onLoadMore();
          }
        }}
      >
        {isLoadMore ? (
          <Loading />
        ) : (
          <Text style={styles.loadMoreText}>もっとロード</Text>
        )}
      </TouchableOpacity>
    );
  };

  const renderItemList = ({ item, index }: any) => {
    return (
      <View>
        <Item key={index} item={item} attrs={attrs} index={index} />
        {getAdmobComponent(index, attrs)}
      </View>
    );
  };

  const keyExtractor = (item: IRecord) => item?._id || item.id;

  const isShowFooterFLatList = paginateArr && totalPage > page;

  const renderList = () => {
    return (
      <View style={styles.cusListWrapper}>
        {dataBinding?.length ? (
          <FlatList
            {...(isShowFooterFLatList && {
              ListFooterComponent: <ListFooterComponent />,
            })}
            horizontal={horizontalScroll}
            numColumns={attrs.columnCount}
            key={attrs.columnCount}
            showsHorizontalScrollIndicator={attrs.isWeb ? true : false}
            data={dataBinding}
            showsVerticalScrollIndicator={true}
            keyExtractor={keyExtractor}
            renderItem={renderItemList}
            onEndReachedThreshold={0.1}
            onScrollEndDrag={() => {
              attrs?.setEnableScrollViewScroll &&
                attrs?.setEnableScrollViewScroll(true);
            }}
            onTouchStart={() => {
              if (contentSizeWidth > width) {
                attrs?.setEnableScrollViewScroll &&
                  attrs?.setEnableScrollViewScroll(false);
              }
            }}
            onContentSizeChange={(width) => {
              setContentSizeWidth(width);
            }}
          />
        ) : null}
      </View>
    );
  };

  return <>{renderList()}</>;
};

export default CusList;
