import { TABLE_DELETE } from '@common/constants/shared';
import { ITable } from '@common/types/element';
import 'antd/dist/antd.css';
import { debounce, forEach, get, map } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import RowComponent from './RowTable';
import SearchComponent from './SearchComponent';
import AntStyles from './style';
import createStyles from './styles';
import useTable from './useTable';

const CusTable: FC<ITable> = (attributes) => {
  const styles = createStyles(attributes);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<Record<string, any>[]>([]);
  const [tableCols, setTableCols] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterText, setFilterText] = useState<string>('');

  const {
    columns,
    pagination: { pageSize, position },
    onPress,
    attributes: { searchInput, selectable },
    databaseOptions,
  } = attributes;

  const defaultWidth = attributes.width / columns?.length;

  const handleDelete = useCallback(async () => {
    if (!databaseOptions?.tableId && selectedRows.length <= 0) return;
    setLoading(true);

    onPress(TABLE_DELETE, {
      ids: selectedRows.map((item) => item?.id),
    })
      .then(() => {
        setLoading(false);
        setSelectedRowKeys([]);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [databaseOptions?.tableId, onPress, selectedRows]);

  const { dataBinding, total, handleChangePage, initializeList, visibility } =
    useTable({
      obj: attributes,
      filterText,
    });

  const handleSearch = debounce((e) => {
    setFilterText(e.target.value);
  }, 500);

  const displayData = useMemo(() => {
    return map(
      dataBinding && Array.isArray(dataBinding) ? dataBinding : [],
      (item: any) => {
        const data: Record<string, any> = {};
        forEach(columns || [], (col) => {
          data[col.id] = get(
            item,
            col.type !== 'actionRef'
              ? `columns.${col.id}.value`
              : `columns.${col.id}.value.text`
          );
        });
        data.id = item.id;
        data.key = item.id;
        data._meta = item._meta;
        return data;
      }
    );
  }, [dataBinding, columns]);

  useEffect(() => {
    const tableColumns =
      columns && columns.length > 0
        ? map(columns, (item: Record<string, any>) => {
            return {
              title: item.title,
              key: item.id,
              dataIndex: item.id,
              width: item.width || defaultWidth,
              render: (data: any, record: Record<string, any>) => (
                <RowComponent
                  data={data}
                  col={item}
                  record={record}
                  onPress={onPress}
                  setLoading={setLoading}
                />
              ),
            };
          })
        : [];

    setTableCols(tableColumns);
  }, [columns, defaultWidth, onPress]);

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onChange: (
        selectedRowsKey: React.Key[],
        selectedRows: Record<string, any>[]
      ) => {
        setSelectedRowKeys(selectedRowsKey);
        setSelectedRows(selectedRows);
      },
    }),
    [selectedRowKeys]
  );

  const disableDeleteButton = useMemo(
    () => selectedRowKeys?.length <= 0,
    [selectedRowKeys]
  );

  if (JSON.stringify(visibility) === 'false')
    return <div style={{ ...styles, zIndex: -100 }} />;

  return (
    <div style={styles}>
      <SearchComponent
        disableDeleteButton={disableDeleteButton}
        disableSearch={!searchInput}
        selectable={selectable}
        handleSearch={handleSearch}
        handleDelete={handleDelete}
        selectedRowKeys={selectedRowKeys}
      />
      <AntStyles
        {...attributes}
        columns={tableCols}
        dataSource={displayData}
        loading={loading || initializeList}
        rowSelection={
          selectable
            ? {
                type: 'checkbox',
                ...rowSelection,
              }
            : undefined
        }
        pagination={{
          position: ['none', position],
          pageSize,
          showSizeChanger: false,
          total: total,
          onChange: handleChangePage,
        }}
        scroll={{ y: attributes.height, x: attributes.width }}
      />
    </div>
  );
};

export default CusTable;
