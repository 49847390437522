import { ISubmitButton } from '@common/types';
import React from 'react';
import {
  TouchableOpacity,
  View,
  Text,
  ActivityIndicator,
  StyleSheet,
} from 'react-native';

interface Props extends ISubmitButton {
  onSubmit: () => void;
  submitting: boolean;
  disabled: boolean;
}

const SubmitButton = (props: Props) => {
  const {
    text = 'SAVE',
    color,
    backgroundColor,
    onSubmit,
    borderRadius,
    borderColor,
    borderWidth,
    fontSize,
    marginTop,
    submitting,
    disabled,
  } = props;

  const wrapperStyles = {
    // ...getShadowStyles(shadow),
    backgroundColor,
    borderRadius,
    borderWidth,
    borderColor,
    height: 40, //fontSize * 1.4 + 2 * padding,
    opacity: submitting ? 0.5 : 1,
  };

  const textStyles = {
    color,
    fontSize,
    // fontWeight:
    //   Platform.OS !== 'android'
    //     ? translateFontWeight(fontWeight, fontFamily, branding)
    //     : null,
    // fontFamily: normalizeFontFamily(fontFamily, branding, { fontWeight }),
  };

  return (
    <TouchableOpacity
      style={{ marginTop }}
      onPress={onSubmit}
      disabled={disabled}
    >
      <View style={[styles.button, wrapperStyles]}>
        <Text style={[styles.buttonText, textStyles]} numberOfLines={2}>
          {text}
        </Text>
      </View>
      {submitting ? (
        <View style={styles.submitProgress}>
          <ActivityIndicator />
        </View>
      ) : null}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    height: 40,
    minHeight: 40,
    borderRadius: 4,
    backgroundColor: '#000',
    alignItems: 'center',
    justifyContent: 'center',
  },
  // buttonWrapper: {
  //   marginTop: 0,
  // },
  buttonText: {
    textAlign: 'center',
    maxWidth: '100%',
    maxHeight: '100%',
    paddingHorizontal: 8,
  },
  submitProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default SubmitButton;
