export default {
  name: 'Button',
  displayName: {
    en: 'Button',
    ja: 'ボタン',
  },
  icon: './icon.png',
  defaultWidth: 155,
  defaultHeight: 44,
  resizeX: true,
  props: [
    {
      name: 'type',
      displayName: {
        en: 'Type',
        ja: 'タイプ',
      },
      type: 'text',
      default: 'contained',
      control: {
        type: 'menu',
        options: [
          {
            label: 'canvas.menuRight.elementTab.button.type.text',
            value: 'text',
          },
          {
            label: 'canvas.menuRight.elementTab.button.text.outline',
            value: 'outlined',
          },
          {
            label: 'canvas.menuRight.elementTab.button.text.button',
            value: 'contained',
          },
        ],
      },
    },
    {
      name: 'text',
      displayName: {
        en: 'Text',
        ja: 'テキスト',
      },
      type: 'text',
      default: 'Button',
      styles: {
        fontFamily: '@body',
        fontWeight: '600',
      },
    },
    {
      name: 'icon',
      displayName: {
        en: 'Icon',
        ja: 'アイコン',
      },
      type: 'icon',
      default: 'add',
    },
    {
      name: 'primaryColor',
      displayName: {
        en: 'Button Color',
        ja: 'Button Color',
      },
      type: 'color',
      default: '@primary',
    },
    {
      name: 'contrastColor',
      displayName: {
        en: 'Icon & Text Color',
        ja: 'アイコンの色',
      },
      type: 'color',
      default: '@contrast:primaryColor',
      enabled: {
        type: 'contained',
      },
    },
    // {
    //   name: 'borderRadius',
    //   displayName: 'Rounding',
    //   type: 'number',
    //   control: {
    //     type: 'slider',
    //     max: 18,
    //     min: 0,
    //   },
    //   default: 4,
    //   enabled: {
    //     type: ['contained', 'outlined'],
    //   },
    // },
    // {
    //   name: 'shadow',
    //   displayName: 'Shadow',
    //   type: 'boolean',
    //   default: true,
    //   enabled: {
    //     type: 'contained',
    //   },
    // },
    {
      name: 'upperCase',
      displayName: {
        en: 'Uppercase',
        ja: '大文字',
      },
      type: 'boolean',
      default: true,
    },
    {
      name: 'action',
      type: 'action',
      displayName: {
        en: 'Button',
        ja: 'ClickFlow',
      },
    },
  ],
};
