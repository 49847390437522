import { ActionStatus } from '@common/constants/shared';
import { getValueFields } from '@common/redux/selectors/formInputs';
import {
  resetValue,
  setDefaultValueInput,
} from '@common/redux/slice/formInputs';
import { Field, IObj, IQuestion, Obj } from '@common/types';
import { getActions } from '@common/utils/handleActions/func/helps';
import { find, forEach, get, isEmpty } from 'lodash';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import FormField from './Fields';
import { generateSchema, IField } from './schema';
import createStyles from './style';
import SubmitButton from './SubmitButton';

type State = {
  loading: boolean;
  notifications: IObj;
  formError: IObj;
  errors: Array<{
    id: string;
    message: string;
  }>;
};

export const ActionContext = createContext({});
export const useActionContext = () => useContext(ActionContext);

const CusQuestion: FC<IQuestion> = (object) => {
  const dispatch = useDispatch();
  const reduxFormFields = useSelector(getValueFields);

  const styles = createStyles(object);

  const [state, setState] = useState<State>({
    loading: false,
    errors: [],
    notifications: {},
    formError: {},
  });
  const [isReset, setReset] = useState(false);

  const {
    onPress,
    fields,
    submitButton,
    hidden,
    automaticFields = [],
    data,
  } = object || {};

  const valuesChanged = useMemo(() => {
    const valueChanges: Obj = {};
    forEach(fields, (field: any) => {
      valueChanges[field.fieldId] =
        reduxFormFields[`${object.id}-${field.fieldId}`];
    });

    return valueChanges;
  }, [fields, object.id, reduxFormFields]);

  const automaticValueField = useMemo(() => {
    return automaticFields.reduce((pre, curr, idx) => {
      const valueField: any = get(data, `automaticFields.${idx}.value`);

      return { ...pre, ...(valueField && { [curr?.fid]: valueField }) };
    }, {});
  }, [automaticFields, data]);

  const handleSubmit = useCallback(() => {
    setState({
      ...state,
      loading: true,
      errors: [],
    });

    const fields: Array<IField | any> = object.fields;
    const schema = generateSchema(fields, 'ja');

    schema
      .validate(valuesChanged, { abortEarly: false })
      .then(function (val) {
        val && setState({ ...state, errors: [] });
        val &&
          onPress(getActions(object.actions), {
            record: { ...val, ...automaticValueField },
            formId: object.id,
          })
            .then((resp: any) => {
              if (!resp) return;
              if (!Array.isArray(resp)) {
                setState((props) => ({
                  ...props,
                  formError: resp?.message,
                }));
              }
              const actionId = getActions(object.actions);
              const actions = get(object, `actions.${actionId}.actions`, []);
              const defaultAction = actions[0];
              const successMessage = find(resp, {
                actionId: defaultAction?.id,
                status: ActionStatus.SUCCESS,
              });

              const errorMessage = find(resp, {
                actionId: defaultAction?.id,
                status: ActionStatus.FAILED,
              });

              if (successMessage) {
                setState((props) => ({
                  ...props,
                  notifications: {
                    ...props.notifications,
                    [object.id]: successMessage.message,
                  },
                }));
                setReset(true);
              }
              if (errorMessage) {
                setState((props) => ({
                  ...props,
                  formError: {
                    ...props.formError,
                    [object?.id]: errorMessage.message,
                  },
                }));
              }
            })
            .then(() => {
              setTimeout(
                () =>
                  setState((props) => ({
                    ...props,
                    formError: { ...props.formError, [object.id]: '' },
                    notifications: { ...props.notifications, [object.id]: '' },
                  })),
                2000
              );
            });
      })
      .catch(function (err: any) {
        const errors: any[] = [];
        err.inner.forEach((e: any) => {
          errors.push({ id: e.path, message: e.message });
        });
        setState({ ...state, errors, loading: false });
      })
      .finally(() => {
        setReset(false);
      });
  }, [state, object, valuesChanged, onPress, automaticValueField]);

  const renderMessage = useCallback(() => {
    return (
      <View>
        <Text style={{ color: 'green' }}>
          {get(state, `notifications.${object?.id}`, '')}
        </Text>
      </View>
    );
  }, [state, object?.id]);

  const renderError = useCallback(() => {
    return (
      <View>
        <Text style={{ color: 'red' }}>
          {get(state, `formError.${object?.id}`, '')}
        </Text>
      </View>
    );
  }, [object?.id, state]);

  useEffect(() => {
    // set default value Form
    if (!object?.actions) return;
    const actionId = getActions(object.actions);
    const actions = get(object, `actions.${actionId}.actions`);

    const handleSetValue = async () => {
      try {
        if (actions.length <= 0) return;
        const defaultAction = actions[0];
        if (defaultAction) {
          const defaultValueForm: any = get(object, 'data', {});
          if (defaultValueForm === null) return;
          const valueInput = fields.reduce(
            (acc, { fieldId, ...fieldProps }, idx) => {
              const fieldValue = defaultValueForm[`fields.${idx}.defaultValue`];
              return {
                ...acc,
                ...(fieldValue && {
                  [`${object.id}-${fieldId}`]:
                    typeof fieldProps.type !== 'string' && fieldValue?.id
                      ? fieldValue?.id
                      : fieldValue,
                }),
              };
            },
            {}
          );
          dispatch(setDefaultValueInput(valueInput));
        }
      } catch (error) {
        console.log('error', error);
      }
    };

    handleSetValue();

    return () => {
      // reset value Form
      const valueField = object.fields.reduce((pre, curr) => {
        return { ...pre, [`${object.id}-${curr.fieldId}`]: '' };
      }, {});
      !isEmpty(valueField) && dispatch(resetValue(valueField));
    };
  }, [dispatch, fields, object]);

  const objectError = get(state.formError, object?.id);
  const objectMessage = get(state.notifications, object?.id);

  return object.database ? (
    <View style={styles.container}>
      {fields &&
        fields.map((field: Field, index) => {
          const fieldId = field?.fid || field.fieldId;
          // * check if field has an error
          const hasError = state.errors.find((err) => err.id === fieldId);

          return (
            <FormField
              key={fieldId}
              object={object}
              field={field}
              active={hidden}
              hasError={hasError}
              index={index}
              isReset={isReset}
            />
          );
        })}
      {objectError ? renderError() : null}
      {!objectError && objectMessage ? renderMessage() : null}

      <SubmitButton
        {...submitButton}
        marginTop={fields.length ? 20 : 0}
        onSubmit={handleSubmit}
        submitting={state.loading}
        disabled={state.loading}
      />
    </View>
  ) : (
    <></>
  );
};

export default CusQuestion;
