import { simpleBindingSelector } from '@common/redux/selectors/page';
import { IRecord, ListObjectProps } from '@common/types';
import { debounce, get, isEmpty, pick } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchRecordList } from '@common/services';
import { appInfoSelector } from '@common/redux/selectors/app';
import { getSourceDataBinding } from '@common/utils/binding';
import { View } from 'react-native';
import queryString from 'query-string';

type DataProp = {
  dataBinding: IRecord[];
  error: any;
  tableSelected: string | any;
  totalPage: number;
  total: number;
};

const ListObjectWrap = ({
  ObjectClass,
  obj,
  dependencies,
  onPress,
  dataBinding,
}: ListObjectProps) => {
  const [data, setData] = useState<DataProp>({
    dataBinding: [],
    error: null,
    tableSelected: null,
    total: 0,
    totalPage: 1,
  });

  const [page, setPage] = useState(1);
  const [initializeList, setInitializeList] = useState(true);
  const [isLoadMore, setLoadMore] = useState(false);
  const [isVisible, setVisible] = useState(true);
  const dataFormPage = useSelector(simpleBindingSelector(obj.id));

  const appInfo = useSelector(appInfoSelector);

  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};
  const target = search?.target;

  const onLoadMore = () => {
    setPage((preState) => preState + 1);
    setLoadMore(true);
  };

  const fetchData = useCallback(
    debounce(async (dependencies, controller, page, obj, componentData) => {
      try {
        if (obj.resizeMode) {
          console.log('resize screen');
          return;
        }

        const rebindingData = getSourceDataBinding(obj);

        const tableId = get(rebindingData, 'source.tableId');

        if (tableId && appInfo) {
          const recordList = await fetchRecordList({
            obj,
            controller,
            page,
            dataBinding,
            dependencies,
          });

          // check visibility in list, dropdown on api list
          setVisible(get(recordList, 'data.visibility', true));

          if (Array.isArray(recordList.data)) {
            setData((preState) => {
              const records = recordList.data;

              return {
                ...preState,
                tableSelected: tableId,
                dataBinding:
                  recordList.limit === 0
                    ? []
                    : recordList.limit
                    ? records.slice(0, recordList.limit)
                    : records,
                total: recordList.total,
                totalPage: recordList.totalPage ?? 0,
              };
            });
            setLoadMore(false);
          }
        } else if (componentData) {
          setData((preState) => {
            return {
              ...preState,
              dataBinding: [componentData],
            };
          });
          setInitializeList(false);
        }
      } catch (error: any) {
        // console.log('fetch list error', error?.response?.data || error);
      } finally {
        setInitializeList(false);
      }
    }, 300),
    [dataBinding]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchData(dependencies, controller, page, obj, dataFormPage);

    return () => {
      controller.abort();
    };
  }, [dependencies, obj, page, appInfo, dataFormPage]);

  useEffect(() => {
    return () => {
      setData({
        dataBinding: [],
        error: null,
        tableSelected: null,
        total: 0,
        totalPage: 1,
      });
    };
  }, [obj.screenUuid]);

  // check visibility in on api page
  useEffect(() => {
    const visibility = get(dataFormPage, 'visibility');
    if (JSON.stringify(visibility) === 'false') {
      setVisible(visibility);
    }
  }, [dataFormPage]);

  if (!isVisible)
    return (
      <View
        style={{
          ...pick(obj, ['width', 'height', 'marginTop', 'marginLeft']),
          zIndex: -100,
        }}
      />
    );
  return (
    <ObjectClass
      {...obj}
      {...data}
      initializeList={initializeList}
      isLoadMore={isLoadMore}
      page={page}
      databaseOptions={{ ...dataBinding?.source, id: dataBinding?.id }}
      onLoadMore={onLoadMore}
      dependencies={dependencies}
      onPress={onPress}
    />
  );
};

export default ListObjectWrap;
