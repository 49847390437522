import { ISelect } from '@common/types/element';
import Icon from '@nocode/components/AppBar/Icon';
import { debounce, isNil } from 'lodash';
import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  ActivityIndicator,
  LayoutChangeEvent,
  Platform,
  ScrollView,
  Text,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import { Menu, TouchableRipple, useTheme } from 'react-native-paper';
import { Theme } from 'react-native-paper/lib/typescript/types';
import createStyles from './style';

export interface DropDownPropsInterface {
  visible: boolean;
  onDismiss: () => void;
  showDropDown: () => void;
  value: string | number | undefined;
  setValue: (_value: string | number) => void;
  label?: string | any;
  placeholder?: string | any;
  mode?: 'outlined' | 'flat' | any;
  list: Array<{
    label: string;
    value: string | number;
    custom?: ReactNode;
  }>;
  dropDownContainerMaxHeight?: number;
  activeColor?: string;
  theme?: Theme;
  dropDownStyle?: ViewStyle;
  dropDownItemStyle?: ViewStyle;
  attributes: ISelect;
  numberOfLines?: number;
}

const DropDown = forwardRef<TouchableWithoutFeedback, DropDownPropsInterface>(
  (props) => {
    const activeTheme = useTheme();
    const {
      visible,
      value,
      activeColor,
      placeholder,
      list,
      dropDownContainerMaxHeight,
      theme,
      dropDownStyle,
      dropDownItemStyle,
      attributes,
      setValue,
      onDismiss,
      showDropDown,
      numberOfLines,
    } = props;

    const [displayValue, setDisplayValue] = useState('');
    const [inputLayout, setInputLayout] = useState({
      height: 0,
      width: 0,
      x: 0,
      y: 0,
    });

    const onLayout = (event: LayoutChangeEvent) => {
      setInputLayout({
        ...event.nativeEvent.layout,
      });
    };

    useEffect(() => {
      const _label = list.find((_) => _.value === value)?.label;
      if (!isNil(_label)) {
        setDisplayValue(_label);
      } else {
        setDisplayValue('');
      }

      return () => setDisplayValue('');
    }, [list, value]);

    const style = createStyles(attributes, value);

    const renderLabel = useCallback(() => {
      return `${displayValue}` || placeholder;
    }, [displayValue, placeholder]);

    const handleScroll = debounce(() => {
      const {
        totalPage,
        page = 1,
        onLoadMore,
        isLoadMore,
        databaseOptions,
      } = attributes;

      if (page < totalPage && !isLoadMore && databaseOptions?.tableId) {
        onLoadMore();
      }
    }, 200);

    return (
      <Menu
        visible={visible}
        onDismiss={onDismiss}
        anchor={
          <TouchableRipple onPress={showDropDown} onLayout={onLayout}>
            <View style={style.container}>
              <Text numberOfLines={1} style={style.text}>
                {renderLabel()}
              </Text>
              <Icon
                name={visible ? 'caret-up' : 'caret-down'}
                size={11}
                color={attributes.color}
              />
            </View>
          </TouchableRipple>
        }
        style={{
          maxWidth: inputLayout?.width,
          width: inputLayout?.width,
          marginTop: inputLayout?.height,
          ...dropDownStyle,
        }}
      >
        <ScrollView
          style={{ maxHeight: dropDownContainerMaxHeight || 200 }}
          onScroll={handleScroll}
          scrollEventThrottle={0.1}
        >
          {list.map((_item, _index) => {
            return (
              <Menu.Item
                key={_index}
                titleStyle={{
                  color:
                    value === _item.value
                      ? //TO DO:
                        // || _item.value === defaultValue?.value
                        activeColor || (theme || activeTheme).colors.primary
                      : (theme || activeTheme).colors.text,
                }}
                onPress={() => {
                  setValue(_item.value);
                  if (onDismiss) {
                    onDismiss();
                  }
                }}
                title={_item.custom || _item.label}
                style={{
                  maxWidth: inputLayout?.width,
                  ...(Platform.OS !== 'android' && Platform.OS !== 'ios'
                    ? {
                        height: 'fit-content',
                      }
                    : {
                        height: 'auto',
                        flex: 0,
                      }),
                  minHeight: 48,
                  paddingBottom: 10,
                  paddingTop: 10,
                  ...dropDownItemStyle,
                }}
                numberOfLines={numberOfLines || 0}
              />
            );
          })}
          {attributes.isLoadMore && <ActivityIndicator />}
        </ScrollView>
      </Menu>
    );
  }
);

export default DropDown;
