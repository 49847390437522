import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { AxiosResponse } from 'axios';
import api from '@common/configs/api';
import { IAdMob, IApp } from '@common/types/element';
import { setAppIDs, setIsUseAdmob } from '@common/redux/slice/admob';

export const initializeAdmob = ({ appInfo }: { appInfo: any }) => {
  const dispatch = useDispatch();
  const [initializingAdmob, setInitializingAdmob] = useState<boolean>(true);

  useEffect(() => {
    setInitializingAdmob(true);

    if (appInfo?.plan) {
      const {
        plan: { permission },
      } = appInfo;
      if (permission?.admob) {
        dispatch(setIsUseAdmob(permission.admob));
      }
    }

    if (appInfo?.id) {
      api({
        method: 'get',
        url: `/v2/share-admob/${appInfo.id}`,
      })
        .then((result: AxiosResponse<IAdMob>) => {
          if (result?.data) {
            dispatch(setAppIDs(result.data));
          }
        })
        .catch((e) => {})
        .finally(() => {
          setInitializingAdmob(false);
        });
    } else {
      setInitializingAdmob(false);
    }
  }, [appInfo]);

  return { initializingAdmob };
};
