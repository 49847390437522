import { appInfoSelector } from '@common/redux/selectors/app';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

function HelmetCustom({ screen }: any) {
  const appInfo = useSelector(appInfoSelector);

  if (!appInfo || !screen) return <Fragment></Fragment>;

  return (
    <Helmet>
      <title>{`${appInfo?.name} | ${screen?.name}`}</title>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${appInfo.metadata.ga4?.id}`}
      ></script>
      <script>
        {` window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());
          gtag('config', '${appInfo.metadata.ga4?.id}');`}
      </script>
    </Helmet>
  );
}

export default HelmetCustom;
