import React, { FC, useMemo, useState } from 'react';
import { Image, ImageSourcePropType, Platform, Text, View } from 'react-native';
import { useSelector } from 'react-redux';

import createStyles from '@common/components/Admob/style';
import Loading from '@common/components/Loading';
import {
  AdmobBannerSize,
  ADMOB_IMAGE_BANNER,
  ADMOB_IMAGE_BANNER_FOR_MOBILE,
} from '@common/constants';
import { admobSelector } from '@common/redux/selectors/admob';

const Admob: FC = (props: any) => {
  const { bannerSize, placeholder } = props;

  const styles = createStyles(props);

  const [errorImage, setErrorImage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { isUseAdmob } = useSelector(admobSelector);

  const imageBannerComponent = useMemo(() => {
    const textPreview =
      'See how this component fits the design of your app in the preview. Disable to hide placeholder from Share URL and PWA.';

    const sourceImage =
      Platform.OS === 'web'
        ? {
            uri:
              ADMOB_IMAGE_BANNER[bannerSize as AdmobBannerSize] ||
              ADMOB_IMAGE_BANNER.banner,
          }
        : ADMOB_IMAGE_BANNER_FOR_MOBILE[bannerSize as AdmobBannerSize] ||
          ADMOB_IMAGE_BANNER_FOR_MOBILE.banner;

    return (
      <Image
        style={{ width: '100%', height: '100%' }}
        resizeMode={'cover'}
        resizeMethod={'scale'}
        source={sourceImage as ImageSourcePropType}
        onLoad={() => {
          setIsLoading(false);
          setErrorImage('');
        }}
        onError={({ nativeEvent: { error } }) => {
          console.error('Error when load image:', error);
          setIsLoading(false);
          setErrorImage(textPreview);
        }}
      />
    );
  }, [bannerSize]);

  const webPreview = useMemo(() => {
    if (placeholder) {
      return (
        <View style={styles.wrapper}>
          {errorImage ? (
            <View style={styles.errorView}>
              <Text>{errorImage}</Text>
            </View>
          ) : (
            <View></View>
          )}
          {isLoading && !errorImage ? <Loading /> : <View></View>}
          {!errorImage && imageBannerComponent}
        </View>
      );
    }
    return <View></View>;
  }, [
    placeholder,
    styles.wrapper,
    styles.errorView,
    errorImage,
    isLoading,
    imageBannerComponent,
  ]);

  if (!isUseAdmob) {
    return <View></View>;
  }

  if (Platform.OS === 'web') {
    return webPreview;
  }
  return <View></View>;
};

export default Admob;
