import { defaultPathComponent } from '@common/constants/shared';
import { ILabel } from '@common/types/element';
import { getValueBindingComponent } from '@common/utils/component';
import {
  getActions,
  getItemListClick,
} from '@common/utils/handleActions/func/helps';
import { isEmpty } from 'lodash';
import React, { FC } from 'react';
import { Text, View } from 'react-native';
import createStyles from './style';

const CusLabel: FC<ILabel> = (attrs) => {
  const text = getValueBindingComponent(
    attrs,
    defaultPathComponent[attrs.type]
  );
  const styles = createStyles(attrs);

  const handlePress = (event: any) => {
    event.preventDefault();
    const arrayAction = getActions(attrs.actions);
    attrs.onPress &&
      attrs.onPress(arrayAction, {
        itemListClick: getItemListClick(attrs?.record),
      });
  };

  return (
    <View style={styles.container}>
      <Text
        style={styles.text}
        numberOfLines={attrs.multiline ? 0 : 1}
        {...(!isEmpty(attrs.actions) ? { onPress: handlePress } : {})}
      >
        {text.replace(/\n$/, '')}
        {attrs.children}
      </Text>
    </View>
  );
};

export default CusLabel;
