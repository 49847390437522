/* eslint-disable react-hooks/rules-of-hooks */
import { bindingComponentType } from '@common/constants/index';
import { appInfoSelector, locationSelector } from '@common/redux/selectors/app';
import {
  currentRecordSelector,
  realtimeDatabaseSelector,
} from '@common/redux/selectors/database';
import { formInputs } from '@common/redux/selectors/formInputs';
import { localeSelector } from '@common/redux/selectors/language';
import { getSourceDataBinding } from '@common/utils/binding';
import {
  checkingTypeComponent,
  eachRecursiveTable,
  flattenInputObject,
} from '@common/utils/component';
import { get, isEmpty, uniq } from 'lodash';
import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

const DependenciesConnector = (BindingComponent: FC<any>) => {
  return (props: any) => {
    const { obj } = props;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const locale = useSelector(localeSelector);
    const databases = useSelector(realtimeDatabaseSelector);
    const currentLocation = useSelector(locationSelector);
    const routeParam = useSelector(currentRecordSelector);
    const appInfor = useSelector(appInfoSelector);
    const formInputState = useSelector(formInputs);

    const getListenChange = (dataBinding: object) => {
      if (isEmpty(dataBinding)) return false;
      return get(dataBinding, 'source.options.listenForChanges', true);
    };

    const table = useMemo(() => {
      const componentType =
        checkingTypeComponent(obj) || bindingComponentType.SIMPLE;
      const tables = eachRecursiveTable(obj);
      const dataBinding = getSourceDataBinding(obj);
      const listenForChange = getListenChange(dataBinding);
      const databaseChange = tables.reduce((preValue: any, current: string) => {
        return { ...preValue, [current]: databases[current] };
      }, {});

      if (
        componentType === bindingComponentType.SIMPLE ||
        (componentType === bindingComponentType.MULTIPLE && listenForChange)
      )
        return databaseChange;

      return [];
    }, [obj, databases]);

    const dependencies = useMemo(() => {
      const inputDependencies = uniq([...flattenInputObject(obj), obj.id]);

      const valueInputs = inputDependencies.reduce(
        (preValue: any, current: string) => {
          const currentValue = formInputState.values[current];
          return {
            ...preValue,
            ...(currentValue && { [current]: formInputState.values[current] }),
          };
        },
        {}
      );

      const valueFormInputs = inputDependencies.reduce(
        (preValue: any, current: string) => {
          const currentValue = formInputState.formValue[current];
          return {
            ...preValue,
            ...(currentValue && {
              [current]: formInputState.formValue[current],
            }),
          };
        },
        {}
      );

      const defaultValues = inputDependencies.reduce(
        (preValue: any, current: string) => {
          const currentValue = formInputState.defaultValues[current];
          return {
            ...preValue,
            ...(currentValue && {
              [current]: formInputState.defaultValues[current],
            }),
          };
        },
        {}
      );

      return {
        appInfor,
        timeZone,
        locale,
        ...(!isEmpty(obj?.currentListIds) && {
          currentListIds: obj?.currentListIds,
        }),
        ...(!isEmpty(valueInputs) && { valueInputs }),
        ...(!isEmpty(valueFormInputs) && { valueFormInputs }),
        ...(!isEmpty(defaultValues) && { defaultValues }),
        ...(!isEmpty(table) && { table }),
        ...(!isEmpty(routeParam) && { routeParam }),
        ...(!isEmpty(currentLocation) && { currentLocation }),
      };
    }, [
      obj,
      appInfor,
      timeZone,
      locale,
      table,
      routeParam,
      currentLocation,
      formInputState.values,
      formInputState.formValue,
      formInputState.defaultValues,
    ]);

    return <BindingComponent {...props} dependencies={dependencies} />;
  };
};

export default DependenciesConnector;
