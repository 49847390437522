import { rowType } from '@common/constants/shared';
import { IObj } from '@common/types/element';
import { isStringArray } from '@common/utils/component';
import { getItemListClick } from '@common/utils/handleActions/func/helps';
import { Button, Checkbox, Image, Tag } from 'antd';
import 'antd/dist/antd.css';
import { get, isEmpty } from 'lodash';
import React, { FC } from 'react';
import styled from 'styled-components';
import {
  FileWrapper,
  ImageMinHeight,
  ImageWrapper,
  styleUnWrapperText,
  styleWrapperText,
} from './style';

type props = {
  data: any;
  col: IObj;
  record: IObj;
  setLoading: (value: boolean) => any;
  onPress: (actionId: string, props: IObj) => any;
};

const TextWrapperStyled = styled.span`
  ${styleWrapperText}
`;

const TextUnWrapperStyled = styled.span`
  ${styleUnWrapperText}
`;

const flatArray = (value: any[] = []) =>
  value.filter((item) => typeof item === 'string').join();

const getImageTable = (col: IObj, data: IObj) => {
  const value = col?.value;
  if (!value) return;

  const { imageType, imageUploaded = '', imageUrl = null } = col.value;

  switch (imageType) {
    case 'uploaded':
      return imageUploaded;

    case 'url':
      if (data) return data;
      if (typeof imageUrl === 'string') return imageUrl;
      else if (Array.isArray(imageUrl) && isStringArray(imageUrl)) {
        return flatArray(imageUrl);
      }
      return null;

    default:
      return data?.url;
  }
};

const getFileUpload = (col: IObj, data: IObj) => {
  const columValue = col.value;

  if (!columValue) return;
  const { fileType = '', fileUploaded, fileUrl } = columValue;

  switch (fileType) {
    case 'uploaded':
      return fileUploaded;

    case 'url':
      if (data) return data;
      if (typeof fileUrl === 'string') return fileUrl;
      else if (Array.isArray(fileUrl) && isStringArray(fileUrl)) {
        return flatArray(fileUrl);
      }
      return null;

    default:
      return data;
  }
};

const convertString = (data: any, defaultValue: any) => {
  if (data) return data;
  else if (Array.isArray(defaultValue)) {
    return flatArray(defaultValue);
  }

  return '';
};

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${(col: any) => col?.value?.position};
  padding-right: ${(col: any) => (col?.value?.position === 'right' ? 16 : 0)}px;
`;

const RowComponent: FC<props> = ({ data, col, onPress, record }) => {
  const fileData = getFileUpload(col, data);
  const checked = data === undefined ? get(col, 'value.binding') : data;
  const bindingOptions = col?.value?.binding?.options;
  const url = getImageTable(col, data);
  const colType = col.value?.type || 'button';
  const type = colType === 'button' ? 'primary' : 'default';
  const colWidthDefault = 130;
  const colMargin = 60;
  const text = get(col, 'value.text', []).join();
  const actionId = get(col, 'onPress.actionId');
  const handleClick = async () => {
    if (!actionId) return;
    onPress(actionId, {
      itemListClick: getItemListClick(record),
    });
  };
  const TagWrapper = col?.wrapperText
    ? styled(Tag)`
        ${styleWrapperText}
      `
    : styled(Tag)`
        ${styleUnWrapperText}
      `;
  const value = convertString(data, col.value);
  const TextWrapper = col?.wrapperText
    ? TextWrapperStyled
    : TextUnWrapperStyled;

  switch (col.type) {
    case rowType.BOOLEAN:
      return <Checkbox checked={checked} disabled />;
    case rowType.IMAGE:
      return !isEmpty(url) || bindingOptions?.placeholderImageEnabled ? (
        <>
          <ImageMinHeight />
          <ImageWrapper>
            <Image
              width="100%"
              height="100%"
              style={{ objectFit: col?.value?.imageResize || 'cover' }}
              src={isEmpty(url) ? bindingOptions?.placeholderImage : url}
            />
          </ImageWrapper>
        </>
      ) : null;
    case rowType.FILE:
      return (
        <FileWrapper href={fileData?.url || fileData || ''} target="_blank">
          {fileData?.filename || fileData?.url || fileData || ''}
        </FileWrapper>
      );
    case rowType.ACTIONREF:
      if (!col.value) return <></>;
      return (
        <ButtonWrapper
          {...col}
          style={{
            justifyContent: col?.value?.position,
            paddingRight: col?.value?.position === 'right' ? 16 : 0,
          }}
        >
          <Button
            style={{
              backgroundColor: col?.value?.backgroundColor,
              color: col?.value?.color,
              ...(colType === 'button' && { borderWidth: 0 }),
              ...(colType === 'text' && {
                borderWidth: 0,
                backgroundColor: '#fff',
              }),
              ...(colType === 'outline' && {
                borderColor: col?.value?.color,
                backgroundColor: '#fff',
              }),
              borderRadius: '5px',
            }}
            type={type}
            onClick={handleClick}
          >
            <p
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: (col?.width || colWidthDefault) - colMargin,
              }}
            >
              {data || text || ' '}
            </p>
          </Button>
        </ButtonWrapper>
      );
    case rowType.TAG:
      return value ? <TagWrapper color={col?.color}>{value}</TagWrapper> : null;
    case rowType.STRING:
      return (
        <TextWrapper key={data}>{convertString(data, col.value)}</TextWrapper>
      );
    default:
      return <>{data || ''}</>;
  }
};

export default RowComponent;
