import { ICalendar } from '@nocode/types';
import {
  CalendarItemBinding,
  IAgendaEvent,
  NavigationBinding,
} from '@nocode/types/calendar.type';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import { Platform, Text, View } from 'react-native';
import { get } from 'lodash';
import { Calendar, LocaleConfig } from 'react-native-calendars';
import { MarkingProps } from 'react-native-calendars/src/calendar/day/marking';
import * as defaultStyle from 'react-native-calendars/src/style';
import Icon from '../AppBar/Icon';
import { getActions, getItemListClick, getValueBinding } from '../shared';
import EventCalendar from './eventCal/EventCalendar';
import { setupLocales } from './locales';
import { ONE_DAY } from '../shared';

const MAX_DOTS_RENDER = 3;
const TIME_FORMAT = 'YYYY-MM-DD';

const _defaultNavigation = {
  defDate: moment(new Date()).format(TIME_FORMAT),
  minDate: '1000-01-01',
  maxDate: '9999-12-31',
};

const Conversions: Record<string, string> = {
  '&nbsp;': '\u00A0',
  '&gt;': '>',
  '&lt;': '<',
  '&amp;': '&',
} as const;

const _parseText = (val: string | Record<string, any>): string =>
  typeof val === 'string'
    ? val.replace(/&(nbsp|amp|quot|lt|gt);/g, (i: string) => Conversions[i])
    : Array.isArray(val)
    ? val
        .join('')
        .replace(/&(nbsp|amp|quot|lt|gt);/g, (i: string) => Conversions[i])
    : 'Error';

const _getInputString = (input: any): string => {
  return _parseText(input);
};

// formats date with optional time
const _formatDate = (date: number | Date, wantTime: boolean = false) => {
  const formatStr = `YYYY-MM-DD${wantTime ? ' HH:mm' : ''}`;
  return moment(date).format(formatStr);
};

const _exampleAgenda = (chosenDay: string | Date) => {
  let passDate = new Date(chosenDay);
  passDate.setDate(passDate.getDate() + 1);
  return {
    id: '',
    start: _formatDate(passDate, false) + ' 00:15',
    end: _formatDate(passDate, false) + ' 5:15',
    title: 'Example Title',
    summary: 'Example Subtitle',
    _meta: {},
  };
};

const _getInitData = (defaultData: {
  items: any;
  navigation: NavigationBinding;
  devicePrev?: string;
}): { items: Array<CalendarItemBinding>; navigation: any } => {
  const { items, navigation, devicePrev } = defaultData;

  const formulaToString = (value: any) => {
    let result: any[] = [];
    let { formula } = value;
    if (Array.isArray(formula)) {
      for (let i = 0; i < formula.length; i++) {
        if (typeof formula[i] === 'string') {
          result.push(formula[i]);
        } else if (formula[i]?.type === 'symbol') {
          result.push(formula[i]?.symbol);
        }
      }
    }
    return result.join('');
  };

  const getDate = (name: 'defDate' | 'maxDate' | 'minDate'): string => {
    const value: any = navigation[name];
    const isValidDate = moment(value.toString()).isValid();

    if (isValidDate) {
      return value.toString();
    }
    if (value?.type === 'formula') {
      return formulaToString(value);
    }

    return _defaultNavigation[name];
  };

  return {
    items: items,
    navigation: {
      changeMonths: navigation.changeMonths,
      defDate: getDate('defDate'),
      maxDate: getDate('maxDate'),
      minDate: getDate('minDate'),
    },
  };
};

// returns an array of all dates between the two inputs
const _getDates = (currentDate: Date, endDate: Date): Array<Date> => {
  const dates: Array<Date> = [];
  let startDate = currentDate;
  while (startDate <= endDate) {
    dates.push(startDate);
    startDate = moment(startDate).add(1, 'days').toDate();
  }
  return dates;
};

const _getMarkDateArray = (items: Array<CalendarItemBinding>) => {
  const markedDatesArray: Array<string> = [];
  const agendaEventTemp: Array<IAgendaEvent> = [];
  for (let i = 0; i < items.length; i++) {
    const eventTitle = get(items[i], 'agenda.eventTitle');
    const eventSubtitle = get(items[i], 'agenda.eventSubtitle');
    const eventStarttime = items[i].eventStarttime * ONE_DAY;
    const eventEndtime = items[i].eventEndtime * ONE_DAY;
    const id = items[i].id;
    const _meta = items[i]?._meta;
    const startTime = _formatDate(eventStarttime);
    const endTime = _formatDate(eventEndtime);
    if (startTime === endTime) {
      if (
        _formatDate(eventStarttime, true) !== _formatDate(eventEndtime, true)
      ) {
        agendaEventTemp.push({
          id,
          start: _formatDate(eventStarttime, true),
          end: _formatDate(eventEndtime, true),
          title: eventTitle,
          summary: eventSubtitle,
          _meta,
        });
        markedDatesArray.push(_formatDate(eventStarttime));
      }
    } else {
      if (
        _formatDate(eventStarttime) + ' 23:59' !==
        _formatDate(eventStarttime, true)
      ) {
        agendaEventTemp.push({
          id,
          start: _formatDate(eventStarttime, true),
          end: _formatDate(eventStarttime) + ' 23:59',
          title: eventTitle,
          summary: eventSubtitle,
          _meta,
        });
        markedDatesArray.push(_formatDate(eventStarttime));
      }

      let dates = _getDates(new Date(startTime), new Date(endTime));
      for (let j = 1; j < dates.length - 1; ++j) {
        let datePush = _formatDate(dates[j]);
        agendaEventTemp.push({
          id,
          start: datePush + ' 00:00',
          end: datePush + ' 23:59',
          title: eventTitle,
          summary: eventSubtitle,
          _meta,
        });
        markedDatesArray.push(datePush);
      }

      if (
        _formatDate(eventEndtime) + ' 00:00' !=
        _formatDate(eventEndtime, true)
      ) {
        agendaEventTemp.push({
          id,
          start: _formatDate(eventEndtime, false) + ' 00:00',
          end: _formatDate(eventEndtime, true),
          title: eventTitle,
          summary: eventSubtitle,
          _meta,
        });
        markedDatesArray.push(_formatDate(eventEndtime, false));
      }
    }
  }
  return { markedDatesArray, agendaEventTemp };
};

type Props = ICalendar;

const _Calendar: React.FC<Props> = (props) => {
  const {
    width,
    height,
    attributes,
    devicePrev,
    onPress,
    zIndex,
    dataBinding,
    initializeList,
  } = props;
  const {
    navigation: { changeMonths },
    fontFamily,
    opacity,
    agenda,
    openAccordion,
  } = attributes;

  // calendar
  const { language, mondayBegin, markingStyle, oneEventAction } = attributes;

  // colors
  const {
    activeColor,
    textColor,
    disabledTextColor: disabledColor,
    backgroundColor: bgColor,
    headingTextColor,
  } = attributes;

  const format24h = +attributes.agenda.format24h;
  const mondayBeginBool = +(mondayBegin === 'Monday');

  //custom font additions
  const customFontStyles: any =
    agenda && agenda.styles
      ? {
          eventTitle: agenda.styles.eventTitle,
          eventSubtitle: agenda.styles.eventSubtitle,
          bodyFont: {
            fontFamily: agenda.styles.eventTitle.fontFamily,
          },
        }
      : {
          eventTitle: { fontFamily },
          eventSubtitle: { fontFamily },
          bodyFont: { fontFamily },
        };

  const [minDate, setMinDate] = useState<string>(_defaultNavigation.minDate);
  const [maxDate, setMaxDate] = useState<string>(_defaultNavigation.maxDate);
  const [currentDate, setCurrentDate] = useState<string>();
  const [chosenDay, setChosenDay] = useState<string>(
    moment().format(TIME_FORMAT)
  );
  const [calendarRender, setCalendarRender] = useState<boolean>(true);
  const [datesHash, setDatesHash] = useState(new Map());
  const [agendaEvents, setAgendaEvents] = useState<Array<IAgendaEvent>>([]);
  const [calAgendaObject, setCalAgendaObject] = useState<
    Record<string, MarkingProps>
  >({});

  setupLocales();

  const updateCalendarObject = (object: Record<string, MarkingProps>) => {
    const objectWithMax3Events: Record<string, MarkingProps> = {};
    Object.keys(object).forEach((day) => {
      objectWithMax3Events[day] = {
        ...object[day],
      };
      let dots = object[day].dots;
      if (dots) {
        const dotsLength = dots?.length || 0;
        if (dotsLength > MAX_DOTS_RENDER) {
          dots = dots?.slice(0, MAX_DOTS_RENDER);
          objectWithMax3Events[day] = {
            ...object[day],
            dots,
          };
        }
      }
    });
    setCalAgendaObject(objectWithMax3Events);
  };

  const updateCurrentDate = (currentDate: string) => {
    setCurrentDate(currentDate);
    const markedDate =
      Object.keys(calAgendaObject).find(
        (day) => calAgendaObject[day]?.selected
      ) || '';

    updateCalendarObject({
      ...calAgendaObject,
      [currentDate]: {
        ...(calAgendaObject[currentDate] || {}),
        selected: true,
      },
      [markedDate]: {
        ...(calAgendaObject[markedDate] || {}),
        selected: false,
      },
    });
  };

  const handlePress = (id: string, item: Record<string, any>) => {
    const options = {
      itemListClick: getItemListClick(item),
    };

    onPress && onPress(getActions(props, id), options);
  };

  // runs when user clicks calendar day
  const onDayPress = (day: { dateString: string }) => {
    if (moment(day.dateString).isBefore(minDate)) {
      return;
    }

    const currentDate = moment(day.dateString).format(TIME_FORMAT);
    updateCurrentDate(currentDate);
    setChosenDay(currentDate);
    if (oneEventAction === 'action' && datesHash.get(day.dateString) === 1) {
      const events = agendaEvents.filter(
        (event: { start: string | string[] }) =>
          event.start.includes(day.dateString)
      );
      if (events?.length === 0) return;

      const { onPressCalendar } = attributes;
      if (onPressCalendar && onPress && typeof onPress === 'function') {
        handlePress('onPressCalendar', events[0]);
      }
    } else {
      setCalendarRender(!calendarRender);
    }
  };

  // runs when user clicks back button on agenda
  const goBack = () => {
    setCalendarRender(!calendarRender);
  };

  // renders multi-dot markings on calendar
  const multiDotRender = (number: number) => {
    const markerDots = { color: activeColor };
    const multiDotArray = [];
    for (let i = 0; i < number; i++) {
      multiDotArray.push(markerDots);
    }
    return multiDotArray;
  };

  // runs when user taps an event
  const eventTapped = (event: IAgendaEvent) => {
    try {
      const { onPressAgendaEvent } = attributes;
      if (onPressAgendaEvent && onPress && typeof onPress === 'function') {
        handlePress('onPressAgendaEvent', event);
        // setTimeout(() => setCalendarRender(true), 1000); // ??
      }
    } catch (e) {}
  };

  useEffect(() => {
    LocaleConfig.defaultLocale = language;
  }, [language]);

  useEffect(() => {
    const bindingEvent = Array.isArray(dataBinding)
      ? dataBinding?.map((item, index) => ({
          ...(getValueBinding('', dataBinding[index] || {}, props) || {}),
          id: item.id,
          _meta: item._meta,
        }))
      : {};

    const { items, navigation } = _getInitData({
      items: bindingEvent,
      navigation: attributes.navigation,
      devicePrev,
    });

    // navigation
    const currentDate = moment(_getInputString(navigation.defDate)).format(
      TIME_FORMAT
    );
    setCurrentDate(currentDate);
    setMinDate(moment(_getInputString(navigation.minDate)).format(TIME_FORMAT));
    setMaxDate(moment(_getInputString(navigation.maxDate)).format(TIME_FORMAT));

    let calAgendaObjectTemp: Record<string, MarkingProps> = {
      [currentDate]: {
        selected: true,
      },
    };

    // agenda
    if (items?.length) {
      const { markedDatesArray, agendaEventTemp } = _getMarkDateArray(items);

      if (items[0]?.eventStarttime === undefined) {
        agendaEventTemp.push({ ..._exampleAgenda(chosenDay) });
      }

      setAgendaEvents(agendaEventTemp);

      let datesHashTemp = new Map();
      markedDatesArray.forEach((day) => {
        if (!datesHashTemp.get(day)) {
          datesHashTemp.set(day, 1);
        } else {
          datesHashTemp.set(day, datesHashTemp.get(day) + 1);
        }
      });
      setDatesHash(datesHashTemp);

      if (markingStyle === 'multi-dot') {
        markedDatesArray.forEach((day) => {
          calAgendaObjectTemp = {
            ...calAgendaObjectTemp,
            [day]: {
              dots: multiDotRender(datesHashTemp.get(day)),
              selected: day === currentDate,
            },
          };
        });
      } else {
        markedDatesArray.forEach((day) => {
          calAgendaObjectTemp = {
            ...calAgendaObjectTemp,
            [day]: {
              periods: [
                { startingDay: false, endingDay: false, color: activeColor },
              ],
              selected: day === currentDate,
            },
          };
        });
      }
    } else {
      setAgendaEvents([]);
    }

    updateCalendarObject(calAgendaObjectTemp);
  }, [attributes.items, attributes.navigation, dataBinding]);

  const renderCalendarEvent = () => {
    let passedTitle = chosenDay;
    try {
      const monthValue = new Date(chosenDay).getMonth() + 1;
      const yearValue = new Date(chosenDay).getFullYear();
      const dayValue = new Date(chosenDay).getDate();
      passedTitle = `${dayValue} ${
        LocaleConfig.locales[language]?.monthNames[monthValue - 1]
      } ${yearValue}`;
      if (language === 'Chinese') {
        passedTitle = `${yearValue} ${
          LocaleConfig.locales[language]?.monthNames[monthValue - 1]
        } ${dayValue}`;
      }
      if (language === 'Japanese') {
        passedTitle = `${yearValue}年${
          LocaleConfig.locales[language]?.monthNames[monthValue - 1]
        }${dayValue}日`;
      }
    } catch (e) {}
    return (
      <EventCalendar
        {...props}
        title={passedTitle}
        headerColor={bgColor}
        headerTextColor={headingTextColor}
        eventBgColor={agenda?.styles?.eventBgColor}
        eventTextColor={agenda?.styles?.eventTitle?.color}
        activeColor={activeColor}
        customFontStyles={customFontStyles}
        key={bgColor + headingTextColor + props.id}
        eventTapped={eventTapped}
        backButton={goBack}
        events={agendaEvents}
        size={1} // default: 5 ????
        initDate={new Date(chosenDay)}
        scrollToFirst
        format24h={+format24h}
      />
    );
  };

  const renderHeader = () => {
    if (language === 'Japanese') {
      return (_currentTime: any) => {
        const time = new Date(_currentTime.toString());
        const dayValue = time.getDate();
        const monthValue = time.getMonth() + 1;
        const yearValue = time.getFullYear();
        return (
          <Text
            allowFontScaling={false}
            style={{
              textAlign: 'center',
              fontSize: 16,
              color: headingTextColor,
              fontWeight: 'bold',
              ...customFontStyles.bodyFont,
            }}
          >
            {`${yearValue}年${
              LocaleConfig.locales[language]?.monthNames[monthValue - 1]
            }`}
          </Text>
        );
      };
    }
    return null;
  };

  const render = () => {
    const theme = {
      calendarBackground: bgColor,
      textSectionTitleColor: textColor,
      selectedDayBackgroundColor: bgColor,
      selectedDayTextColor: activeColor,
      todayTextColor: textColor,
      dayTextColor: textColor,
      textDisabledColor: disabledColor,
      dotColor: activeColor,
      selectedDotColor: activeColor,
      monthTextColor: headingTextColor,
      textDayFontWeight: '400',
      textMonthFontWeight: 'bold',
      textDayHeaderFontWeight: '500',
      textDayFontSize: 16,
      textMonthFontSize: 16,
      textDayHeaderFontSize: 16,
      textDayFontFamily: customFontStyles.bodyFont.fontFamily,
      textMonthFontFamily: customFontStyles.bodyFont.fontFamily,
      dotStyle: {
        alignItems: 'center',
      },
      'stylesheet.day.basic': {
        base: {
          height: 45,
          alignItems: 'center',
        },
      },
      'stylesheet.calendar.header': {
        dayHeader: {
          marginTop: 2,
          marginBottom: 7,
          width: 50,
          textAlign: 'center',
          fontSize: defaultStyle.textDayHeaderFontSize,
          color: textColor,
          ...customFontStyles.bodyFont,
        },
      },
    };

    if (initializeList) {
      return <></>;
    }

    return (
      <View style={{ flex: 1, opacity, marginTop: 20, width, height, zIndex }}>
        {calendarRender && currentDate ? (
          <Calendar
            key={
              activeColor +
              textColor +
              disabledColor +
              bgColor +
              headingTextColor +
              props.name +
              props.id
            }
            theme={theme}
            firstDay={mondayBeginBool}
            onDayPress={onDayPress}
            minDate={new Date(minDate)}
            maxDate={new Date(maxDate)}
            current={new Date(currentDate)}
            hideArrows={!changeMonths}
            renderArrow={(direction: string) =>
              direction === 'left' ? (
                <Icon name={'chevron-left'} color={activeColor} />
              ) : (
                <Icon name={'chevron-right'} color={activeColor} />
              )
            }
            renderHeader={renderHeader()}
            onPressArrowLeft={(subtractMonth: () => any) => subtractMonth()}
            onPressArrowRight={(addMonth: () => any) => addMonth()}
            markedDates={calAgendaObject}
            markingType={markingStyle}
            enableSwipeMonths={true}
          />
        ) : (
          renderCalendarEvent()
        )}
      </View>
    );
  };

  return render();
};

export default memo(_Calendar);
