import { CURRENT_TIME } from '@common/constants/shared';
import { simpleBindingSelector } from '@common/redux/selectors/page';
import { IOnPressProps } from '@common/types/action';
import { ComponentType } from '@common/types/element';
import { assign, get, isEmpty, pick } from 'lodash';
import React, { FC, useEffect, useState, useMemo } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';

type Props = {
  ObjectClass: FC;
  obj: any;
  dependencies: any;
  onPress: (actionId: string, options: IOnPressProps) => any;
};

const date = new Date();

const SimpleObjectWrap = ({
  ObjectClass,
  obj,
  dependencies,
  onPress,
}: Props): JSX.Element => {
  const [visibility, setVisibility] = useState(false);

  const data = useSelector(simpleBindingSelector(obj.id));

  const isCurrentTime = useMemo(() => {
    const defaultDateValue = obj?.defaultDateValue;
    return (
      (obj.type === ComponentType.datePicker &&
        CURRENT_TIME.includes(defaultDateValue)) ||
      CURRENT_TIME.includes(defaultDateValue?.label)
    );
  }, [obj]);

  useEffect(() => {
    if (isEmpty(obj?.visibilities)) {
      setVisibility(true);
    } else {
      if (obj?.record) {
        setVisibility(get(obj?.record, `${obj.id}.visibility`, false));
      } else {
        setVisibility(get(data, 'visibility', false));
      }
    }
  }, [data, obj]);

  //visibility
  if (JSON.stringify(visibility) === 'false')
    return (
      <View
        style={{
          ...pick(obj, ['width', 'height', 'marginTop', 'marginLeft']),
          zIndex: -1,
        }}
      />
    );

  return (
    <ObjectClass
      {...(!isCurrentTime
        ? obj
        : assign(obj, { defaultDateValue: date.toISOString() }))}
      data={data}
      dependencies={dependencies}
      onPress={onPress}
    />
  );
};

export default SimpleObjectWrap;
