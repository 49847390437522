import { IChipList } from '@nocode/types';
import { get } from 'lodash';
import React from 'react';
import { Image, View } from 'react-native';
import { Chip, Paragraph } from 'react-native-paper';
import { defaultURL } from '../../../utils/common.constant';
import {
  getActions,
  getImageCardCover,
  getItemListClick,
  getValueBinding,
  isCheckColor,
} from '../../shared';
import createStyles from '../style';

type Props = {
  attrs: IChipList;
  index: number;
  item: any;
  onPress?: any;
};

const ChipItem = ({ attrs, index, item, onPress }: Props) => {
  const styles = createStyles(attrs);

  const {
    closeIcon = { enabled: false },
    title = { enabled: false },
    cardCover,
  } = attrs.attributes;

  const placeholderImageUrl = get(
    attrs,
    'attributes.cardCover.image.binding.options.placeholderImage',
    null
  );

  const placeholderImageEnabled = get(
    attrs,
    'attributes.cardCover.image.binding.options.placeholderImageEnabled',
    false
  );

  const handlePress = (id: string, item: Record<string, any>) => {
    const options = {
      itemListClick: getItemListClick(item.record),
    };
    onPress && onPress(getActions(attrs, id), options);
  };

  const imageURL = getImageCardCover(item, cardCover);

  const bindingValue = getValueBinding('', item, attrs);

  return (
    <Chip
      {...(closeIcon.enabled && closeIcon.icon !== ''
        ? {
            closeIcon: closeIcon.icon,
            onClose: () => {
              handlePress('closeIcon', {
                record: item,
                indexRecord: index,
              });
            },
            selectedColor: isCheckColor(attrs.attributes.iconColor)
              ? attrs.attributes.iconColor
              : '#000000',
          }
        : {})}
      {...(attrs.attributes?.onPress && {
        onPress: () => {
          handlePress('onPress', {
            record: item,
            indexRecord: index,
          });
        },
      })}
      style={styles.chipContent}
      textStyle={styles.text}
      avatar={
        <View style={styles.avatar}>
          <Image
            style={{
              height: '100%',
              width: '100%',
              borderRadius: cardCover.borderRadius,
            }}
            defaultSource={{
              uri: placeholderImageEnabled ? placeholderImageUrl : defaultURL,
            }}
            source={{
              uri: imageURL,
            }}
          />
        </View>
      }
    >
      {title.enabled && (
        <Paragraph style={styles.text}>
          {get(bindingValue, 'title.text')}
        </Paragraph>
      )}
    </Chip>
  );
};

export default ChipItem;
