import { Button, Input, Popconfirm } from 'antd';
import React, { FC } from 'react';

const SearchComponent: FC<Record<string, any>> = ({
  disableDeleteButton,
  disableSearch,
  handleSearch,
  handleDelete,
  selectable,
  selectedRowKeys,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'end',
        marginBottom: 5,
      }}
    >
      {selectable && (
        <Popconfirm
          title={`Are you sure to delete ${selectedRowKeys.length} records ?`}
          onConfirm={handleDelete}
          okText="Yes"
          cancelText="No"
        >
          <Button
            disabled={disableDeleteButton}
            danger
            type="primary"
            // onClick={handleDelete}
          >
            Delete
          </Button>
        </Popconfirm>
      )}

      {!disableSearch && (
        <Input.Search
          style={{ width: 300, marginLeft: 5 }}
          placeholder="Search"
          onChange={handleSearch}
        />
      )}
    </div>
  );
};

export default SearchComponent;
