import { formValueSelector } from '@common/redux/selectors/formInputs';
import { bindingLoaderSelector } from '@common/redux/selectors/page';
import {
  setDefaultValueInput,
  setFormValue,
  setValueInput,
} from '@common/redux/slice/formInputs';
import { getDefaultValue, memoriesConditional } from '@common/utils/component';
import { get, isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const connectorInput = (Component: any) =>
  React.memo((props: any) => {
    const reduxFormFields = useSelector(formValueSelector);

    const { dependencies = {} } = props;
    const { valueInputs, defaultValues = {} } = dependencies;
    const dispatch = useDispatch();
    const loading = useSelector(bindingLoaderSelector);

    const changeInput = useCallback(
      (value: any) => {
        const valueInput = {
          [props.id]: value,
        };

        if (props?.isFormItem) {
          dispatch(setFormValue(valueInput));
        } else {
          dispatch(setValueInput(valueInput));
        }
      },
      [dispatch, props.id, props?.isFormItem]
    );

    const setDefaultValue = useCallback(
      (value: string) => {
        const valueInput = {
          [props.id]: value,
        };
        dispatch(setDefaultValueInput(valueInput));
      },
      [dispatch, props.id]
    );
    const valueInput = useMemo(() => {
      if (props?.isFormItem) {
        return get(reduxFormFields, props.id);
      }
      return get(valueInputs, props.id);
    }, [props?.isFormItem, props.id, valueInputs, reduxFormFields]);

    const placeholder = useMemo(
      () => get(props, 'defaultValue.binding.options', props?.placeholder),
      [props]
    );

    const params = { changeInput, setDefaultValue, valueInput, placeholder };

    useEffect(() => {
      if (props?.isFormItem || loading) return;
      const defaultValue = getDefaultValue(props);
      if (defaultValue && !isEqual(defaultValue, defaultValues[props.id])) {
        setDefaultValue(defaultValue);
      }
    }, [defaultValues, loading, props, setDefaultValue]);

    return <Component {...props} {...params} />;
  }, memoriesConditional(['dependencies', 'data', 'id', 'dataBinding']));

export default connectorInput;
