import LineIcon from '@nocode/components/LineLogin/LineIcon';
import createStyles from '@nocode/components/LineLogin/style';
import { ILineButton } from '@nocode/types';
import { get } from 'lodash';
import qs from 'query-string';
import React, { useEffect } from 'react';
import {
  EmitterSubscription,
  Linking,
  NativeModules,
  Platform,
  Text,
  TouchableHighlight,
  View,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getActions, getItemListClick, getValueBinding } from '../shared';
let eventDeepLinkUrl: EmitterSubscription;

const LineLogin: React.FC<ILineButton> = (props) => {
  const lineTokenUrl = 'https://access.line.me/oauth2/v2.1/authorize';

  const {
    attributes: { socialConfig, textError },
    onPress,
    id,
    data,
  } = props;

  const styles = createStyles(props.attributes);
  const currentUrl = window?.location?.href?.toString();

  if (
    !currentUrl?.includes('canvas') &&
    (!socialConfig || !socialConfig.clientId || !socialConfig.clientSecret)
  ) {
    return (
      <View style={styles.errorContainer}>
        <Text style={styles.errorText}>{textError?.en}</Text>
      </View>
    );
  }
  const bindingValue = getValueBinding(id, data, props);

  const onPressLine = async () => {
    try {
      const actionId = getActions(props, 'action');

      if (!actionId) return;

      await AsyncStorage.setItem(
        'penddingActions',
        JSON.stringify({
          objectId: props.id,
          actionId,
          arrayAction: props.actions[actionId]?.actions,
          screenUuid: props?.screenUuid,
        })
      );

      if (Platform.OS === 'web') {
        const queryParams = new URLSearchParams(location.search);
        if (navigator.userAgent.indexOf('Line/') === -1) {
          let textErr = 'Please open in Line browser!';
          if (queryParams.has('locale') && queryParams.get('locale') === 'ja') {
            textErr = 'Lineブラウザーで開いてください！';
          }
          alert(textErr);
          return {
            status: 'FAILED',
          };
        }
        let currentUrl = location.pathname;

        const url = `${lineTokenUrl}?response_type=code&client_id=${socialConfig?.clientId}&redirect_uri=${socialConfig?.redirectUri}&state=${currentUrl}&scope=${socialConfig?.scope}`;
        Linking.openURL(url);
      } else {
        const scheme =
          NativeModules?.ReactNativeConfigModule?.SCHEME_DEEP_LINK_APP;

        const url = `${lineTokenUrl}?response_type=code&client_id=${socialConfig?.clientId}&redirect_uri=${socialConfig?.redirectUri}&state=${scheme}&scope=${socialConfig?.scope}`;

        Linking.openURL(url);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <TouchableHighlight onPress={onPressLine}>
        <View style={styles.container}>
          <LineIcon />
          <Text style={styles.text}>{get(bindingValue, 'text')}</Text>
        </View>
      </TouchableHighlight>
    </>
  );
};

export default LineLogin;
