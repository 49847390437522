export default {
  name: 'Calendar',
  displayName: {
    en: 'Calendar',
    ja: 'カレンダー',
  },
  icon: './icon.png',
  defaultWidth: 375,
  defaultHeight: 400,
  resizeY: true,
  resizeX: true,
  props: [
    {
      name: 'items',
      displayName: {
        en: 'What events should be displayed?',
        ja: '表示するデータを選択してください',
      },
      type: 'list',
      // listControl: { sort: false, limit: false },
    },
    {
      name: 'eventStarttime',
      displayName: {
        en: 'Event Start',
        ja: 'イベント開始日',
      },
      role: 'listItem',
      reference: 'items',
      type: 'number',
    },
    {
      name: 'eventEndtime',
      displayName: {
        en: 'Event End',
        ja: 'イベント終了日',
      },
      role: 'listItem',
      reference: 'items',
      type: 'number',
    },
    {
      name: 'onPressCalendar',
      displayName: {
        en: 'On press event',
        ja: 'イベントクリック時の動作',
      },
      type: 'action',
      role: 'listItem',
      reference: 'items',
      tabType: 'click-flow',
      enabled: {
        oneEventAction: ['action', 'CLICK_INTERNAL_!previous'],
      },
    },
    {
      name: 'onPressAgendaEvent',
      displayName: {
        en: 'On press agenda event',
        ja: '予定表ビューをクリック時の動作',
      },
      reference: 'items',
      role: 'listItem',
      type: 'action',
      tabType: 'click-flow',
    },
    {
      name: 'language',
      displayName: {
        en: 'Language',
        ja: '表示言語',
      },
      type: 'text',
      default: 'English',
      control: {
        type: 'menu',
        options: [
          {
            label: '日本語',
            value: 'Japanese',
          },
          {
            label: 'English',
            value: 'English',
          },
          // {
          //   label: 'Arabic',
          //   value: 'Arabic',
          // },
          // {
          //   label: 'Chinese',
          //   value: 'Chinese',
          // },
          // {
          //   label: 'French',
          //   value: 'French',
          // },
          // {
          //   label: 'German',
          //   value: 'German',
          // },
          // {
          //   label: 'Hindi',
          //   value: 'Hindi',
          // },
          // {
          //   label: 'Portuguese',
          //   value: 'Portuguese',
          // },
          // {
          //   label: 'Spanish',
          //   value: 'Spanish',
          // },
          // {
          //   label: 'Russian',
          //   value: 'Russian',
          // },
          // {
          //   label: 'Polish',
          //   value: 'Polish',
          // },
        ],
      },
    },
    {
      name: 'mondayBegin',
      displayName: {
        en: 'First Day of the Week',
        ja: '週の始まり',
      },
      type: 'text',
      default: 'Sunday',
      control: {
        type: 'menu',
        options: [
          {
            label: 'canvas.menuRight.elementTab.calendar.sunday',
            value: 'Sunday',
          },
          {
            label: 'canvas.menuRight.elementTab.calendar.monday',
            value: 'Monday',
          },
        ],
      },
    },
    {
      name: 'markingStyle',
      displayName: {
        en: 'Multi-Day Event Marking Style',
        ja: 'イベントの表示形式',
      },
      type: 'text',
      default: 'multi-dot',
      control: {
        type: 'menu',
        options: [
          {
            label: 'canvas.menuRight.elementTab.calendar.dots',
            value: 'multi-dot',
          },
          {
            label: 'canvas.menuRight.elementTab.calendar.bars',
            value: 'multi-period',
          },
        ],
      },
    },
    {
      name: 'oneEventAction',
      displayName: {
        en: 'Clicking days with only one event should...',
        ja: 'カレンダーをクリックした時の動作(１日１イベントの場合)',
      },
      type: 'text',
      default: 'agenda',
      helpText: 'canvas.menuRight.elementTab.calendar.oneEventAction.helpText',
      control: {
        type: 'menu',
        options: [
          {
            label:
              'canvas.menuRight.elementTab.calendar.oneEventAction.runAnAction',
            value: 'action',
          },
          {
            label:
              'canvas.menuRight.elementTab.calendar.oneEventAction.openTheAgendaView',
            value: 'agenda',
          },
        ],
      },
    },
  ],
  childComponents: [
    // navigation props
    {
      name: 'navigation',
      displayName: {
        en: 'Navigation',
        ja: '選択日のオプション',
      },
      props: [
        {
          name: 'defDate',
          displayName: {
            en: 'Default Selected Date',
            ja: '初期の選択日',
          },
          type: 'date',
          placeholder: 'YYYY-MM-DD',
        },
        {
          name: 'minDate',
          displayName: {
            en: 'Earliest Selectable Date',
            ja: '選択可能な開始日',
          },
          type: 'date',
          default: '2019-01-01',
          placeholder: 'YYYY-MM-DD',
        },
        {
          name: 'maxDate',
          displayName: {
            en: 'Latest Selectable Date',
            ja: '選択可能な終了日',
          },
          type: 'date',
          default: '2030-01-01',
          placeholder: 'YYYY-MM-DD',
        },
        {
          name: 'changeMonths',
          displayName: {
            en: 'Allow Changing Months?',
            ja: '表示月の変更を許可しますか？',
          },
          type: 'boolean',
          default: true,
        },
      ],
    },
    // agenda props
    {
      name: 'agenda',
      displayName: {
        en: 'Agenda View',
        ja: '予定表ビュー',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'format24h',
          displayName: {
            en: 'Time Format',
            ja: '時間の形式',
          },
          type: 'boolean',
          defaultValue: false,
          control: {
            type: 'menu',
            options: [
              {
                label: 'canvas.menuRight.elementTab.calendar.12HourTime',
                value: false,
              },
              {
                label: 'canvas.menuRight.elementTab.calendar.24HourTime',
                value: true,
              },
            ],
          },
        },
        // {
        //   name: 'eventBgColor',
        //   displayName: {
        //     en: 'Event Background Color',
        //     ja: 'Event Background Color',
        //   },
        //   type: 'text',
        //   default: '',
        //   disableDynamicValues: true,
        // },
        {
          name: 'eventTitle',
          displayName: {
            en: 'Event Titles',
            ja: 'イベントタイトル',
          },
          type: 'text',
          // styles: {
          //   fontFamily: '@body',
          //   fontWeight: 'normal',
          //   fontSize: '15',
          //   // color: '@contrast:eventBgColor',
          // },
        },
        {
          name: 'eventSubtitle',
          displayName: {
            en: 'Event Subtitles',
            ja: 'イベントサブタイトル',
          },
          type: 'text',
          // styles: {
          //   fontFamily: '@body',
          //   fontWeight: 'normal',
          //   fontSize: '12',
          //   color: '@contrast:eventBgColor',
          // },
        },
      ],
    },
  ],
};
